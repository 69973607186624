
<div class="col-xs-12 title"><em class="fas fa-portrait pr-3"></em><span>FollowUp Reports</span></div>
<div class="col-xs-12 p-0">
  <div class="of-auto" style="max-height:150px;" [beforeBottomView]="beforeBottomCallback">
    <div class="fontS mb-3 col-xs-12 flex p-0" *ngFor="let report of followUpReports; let i = index;" title="{{report.createdAt | amDateFormat: 'MMM DD, YYYY hh:mm a'}}">
      <a class="text-info mr-3" href="user/follow-up-report/{{report.id}}?username={{chatUser?.get('username')}}" target="_blank">{{report.get('type')}}</a>
      <span class="mr-2">{{report.createdAt | amDateFormat: 'MMM DD, YYYY hh:mm a'}}</span>
      <a class="fa fa-pencil-square-o mr-2" target="_blank" href="compare/{{ report.get('afterInstantCheckup')?.id }}/{{ report.get('beforeInstantCheckup')?.id }}/{{report.get('user').get('username')}}?edit={{report.id}}"></a>
    </div>
  </div>
</div>