import { v4 as uuid } from 'uuid';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppConfig } from '../../../../../app.config';
import { InstructionVideoUploadDialog } from '../../../../../../components/instruction-video-upload/instruction-video-upload.component';
import { Broadcaster } from '../../../../../../components/broadcaster';

@Component({ selector: 'catalog-instruction-edit', templateUrl: './catalog-instruction-edit.html' })
export class CatalogInstructionEditModal {
  instruction: any = { id: uuid() };
  productObj: any;
  isNewInstruction: boolean;
  constructor(private dialogRef: MatDialogRef<CatalogInstructionEditModal>,
    public appConfig: AppConfig,
    private readonly broadcasterService: Broadcaster,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.instruction = this.data.instruction || {};
    this.isNewInstruction = !this.instruction.id;
    if (this.isNewInstruction) {
      this.instruction.id = uuid();
    }
    this.productObj = this.data.productObj;
  }

  hideModal(): void {
    this.dialogRef.close();
  }

  public async saveInstruction(): Promise<void> {
    try {
      const instructions: Array<any> = this.productObj.get('instructions') || [];
      if (this.isNewInstruction) {
        instructions.push(this.instruction);
      } else {
        instructions[instructions.findIndex((each: any) => each.id === this.instruction.id)] = this.instruction;
      }
      await this.productObj.save({ instructions });
      await this.showAlertIfInstructionVideoNotFount(this.productObj);
      this.dialogRef.close();
    } catch (error) {
      alert(error.message);
    }
  }

  async updatepaidVideo(): Promise<void> {
    this.dialog.open(InstructionVideoUploadDialog, { data: { instruction: this.instruction,
      paidOrUnpaid: this.appConfig.Shared.Catalog.INSTRUCTION_VIDEO_TYPE.PAID } })
      .afterClosed().subscribe((result: any) => {
        if (!result) return;
        this.instruction.paidInstructionVideo = result;
      });
  }

  async updateUnpaidVideo(): Promise<void> {
    this.dialog.open(InstructionVideoUploadDialog, { data: { instruction: this.instruction,
      paidOrUnpaid: this.appConfig.Shared.Catalog.INSTRUCTION_VIDEO_TYPE.UNPAID } })
      .afterClosed().subscribe((result: any) => {
        if (!Object.values(result).length) return;
        this.instruction.unpaidInstructionVideo = result;
      });
  }

  private async showAlertIfInstructionVideoNotFount(productObj: Table.Catalog): Promise<void> {
    await productObj.fetch();
    const instructions = this.productObj.get('instructions') || [];
    const instruction = instructions[instructions.findIndex((each: any) => each.creationId === this.instruction.creationId)];
    if (!instruction.paidInstructionVideo?.en || !instruction.paidInstructionVideo?.hi) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'paidInstructionVideo not found.',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    if (!instruction.unpaidInstructionVideo?.en || !instruction.unpaidInstructionVideo?.hi) {
      this.broadcasterService.broadcast('NOTIFY', { message: 'unpaidInstructionVideo not found.',
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }
}
