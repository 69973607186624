import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiConnector, RequestQueryPayload, ApiClientConstant } from 'api-client';
import { Subscription } from 'rxjs';
import { Broadcaster } from 'src/components/broadcaster';
import { ConnectionService } from 'src/services/connection-service';
import { MatDialog } from '@angular/material/dialog';
import { SalesService } from './services/sales.service';
import { AppConfig } from '../app.config';
import { MainConcernModal } from './components/modal/main-concern/main-concern-modal-component';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrl: './sales.component.scss',
})
export class SalesComponent {
  userId: any;
  subscriptions: Array<Subscription> = [];
  eventSubscription: Subscription;
  mainConcernOptions: Array<any> = [];
  username: string;
  webAppUrl: any;
  consultations: any;
  selectedConsultation: any;
  regimens: any = [];
  orders: any = [];
  instantCheckups: any = [];
  ordersList: any = [];
  // Global variables that are being used in child components
  chatUser: any;
  orderObj: any;
  parseOrderObj: any;
  loading: boolean = false;
  note: any;

  constructor(private conn: ConnectionService,
    private route: ActivatedRoute,
    private dom: DomSanitizer,
    private dialog: MatDialog,
    private broadcaster: Broadcaster,
    private appConfig: AppConfig,
    private salesService: SalesService,
  ) {
    this.eventSubscription = this.salesService.eventObservable$.subscribe((data: any): void => {
      if (data.orderObj && data.note) {
        this.parseOrderObj = data.orderObj;
        this.note = data.note;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.route.queryParams.subscribe(async (params: any): Promise<void> => {
      this.username = params?.username || '';
    });
    this.chatUser = await this.conn.getUserByUserName(this.username);
    this.consultations = await this.conn.findConsultationSession({
      where: { user: this.chatUser },
      descending: 'createdAt',
      project: ['PrivateMainConcernClass', 'PrivateMainConcern'],
    });
    if (!this.consultations?.length) {
      return;
    }
    await this.getRegimens();
    await this.fetchOrderHistory();
    this.setConsultationChat();
    this.loading = false;
  }

  setConsultationChat(): void {
    if (this.orderObj?.regimen) {
      this.selectedConsultation = this.consultations.find((consultation: any): boolean => {
        const privateConcern = consultation?.get('PrivateMainConcernClass');
        return privateConcern === this.orderObj?.regimen?.class;
      });
      this.webAppUrl = this.dom.bypassSecurityTrustResourceUrl(
        `${this.conn.getWebAppUrl()}/chatV2/${this.selectedConsultation?.id}?username=${this.username}`
        + '&type=consultationSession&loginType=token');
    } else {
      this.webAppUrl = this.dom.bypassSecurityTrustResourceUrl(
        `${this.conn.getWebAppUrl()}/user/checkup?loginType=token&username=${this.username}`);
    }
  }

  async getRegimens(): Promise<void> {
    const regimens = await this.conn.findRegimens({
      where: { forUser: this.chatUser },
      include: ['concernsLanguageString', 'night.product' as 'night', 'morning.product' as 'morning',
        'variants.products' as 'variants',
        'variants.regimen.products.product.title' as 'variants'],
      project: ['regimenId',
        'title', 'active', 'class', 'variants', 'fixedPrice'],
    });
    this.regimens = JSON.parse((JSON.stringify(regimens)));
  }

  async fetchOrderHistory(): Promise<void> {
    const payload: RequestQueryPayload<Table.Order> = {
      where: {
        user: this.chatUser,
        type: ApiClientConstant.Order.Type.REGIMEN,
      },
      include: ['products', 'services', 'regimen', 'addressBook'],
      descending: 'createdAt',
      project: [
        'regimenId',
        'allocatedDoctor',
        'labels',
        'deliveryAddress',
        'actualPrice',
        'services',
        'stage',
        'deliveredOn',
        'regimen.optedForDoctorCall' as 'regimen',
        'regimen.products.title' as 'products',
        'regimen.products.quantity' as 'products',
        'regimen.products.quantityUnit' as 'products',
        'regimen.products.allowAsAddon' as 'products',
        'regimen.products.type' as 'products',
        'regimen.products.price' as 'products',
        'regimen.extraProducts' as 'regimen',
        'services.type' as 'services',
        'services.title' as 'services',
        'regimen',
        'amount',
        'productInfo',
        'type',
        'notes',
        'createdBy',
        'actualCreatedAt',
        'updatedAt',
        'couponInfo',
        'salesAgent',
        'paymentType',
        'trackingId',
        'addressBook',
      ],
    };
    this.ordersList = await this.conn.findOrders(payload);
    let orderIndex: number;
    this.orders = this.ordersList.map((each: any) => each.toJSON());
    const skinOrder = this.orders.find((order: any, index: number): boolean => {
      orderIndex = index;
      return order?.regimen?.class === ApiClientConstant.InstantCheckup.Type.FACE;
    });
    if (skinOrder) {
      this.orderObj = skinOrder;
      this.parseOrderObj = this.ordersList[orderIndex];
    } else {
      const hairOrder = this.orders.find((order: any, index: number): boolean => {
        orderIndex = index;
        return order?.regimen?.class === ApiClientConstant.InstantCheckup.Type.HAIR;
      });
      this.orderObj = hairOrder;
      this.parseOrderObj = this.ordersList[orderIndex];
    }
    const data = { orderId: this.orderObj?.objectId };
    try {
      const checkoutResponse = await ApiConnector.cloudRun('checkoutAndCreateOrder', data);
      this.orderObj = JSON.parse(JSON.stringify(checkoutResponse));
    } catch (error) {
      alert(error.message);
    }
  }

  async retriggerTree(): Promise<void> {
    this.mainConcernOptions = await this.conn.findMainConcernsOptions();
    const dialogRef = this.dialog.open(MainConcernModal, {
      data: {
        mainConcernOptions: this.mainConcernOptions,
        userConcern: this.selectedConsultation?.get('PrivateMainConcern'),
        user: this.chatUser,
        selectedConsultation: this.selectedConsultation,
      },
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe(async (result: any): Promise<void> => {
    }));
  }

  updateOrderObject(type: string): void {
    let orderIndex: number;
    this.orderObj = this.orders.find((order: any, index: number): boolean => {
      orderIndex = index;
      return order.objectId === type;
    });
    this.parseOrderObj = this.ordersList[orderIndex];
    this.setConsultationChat();
  }

  ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }
}
