import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { ApiClientConstant, Table, RequestQueryPayload, ApiConnector } from 'api-client';
import { PendingCallCompleteComponent } from '../pending-call-complete/pending-call-complete.component';
import { ConnectionService } from '../../../../services/connection-service';
import { Broadcaster } from '../../../../components/broadcaster';
import { AppConfig } from '../../../app.config';
import { PendingCallRecentHistoryComponent } from '../pending-call-recent-history/pending-call-recent-history.component';

@Component({ selector: 'pending-calls-action', templateUrl: './pending-calls-action.html' })
export class PendingCallsActionComponent implements ICellRendererAngularComp {
  private id: string;
  params: ICellRendererParams;
  primaryNumber: string;
  secondaryNumber: string;
  link: string;
  completed: boolean = false;
  userTypes: Array<string> = [];
  showPendingCallsAction: boolean = false;

  constructor(
    private dialog: MatDialog,
    private conn: ConnectionService,
    private appConfig: AppConfig,
    private broadcaster: Broadcaster) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (!this.params.data) {
      return;
    }
    if (this.params?.data?.allocatedOperator) {
      this.showPendingCallsAction = true;
    }
    this.id = params.value;
    this.primaryNumber = this.params.data.user?.MobileNumber || this.params.data.referenceId;
    this.secondaryNumber = this.params.data.user?.alternateNumber;
    if (!this.userTypes.length) {
      this.userTypes = this.conn.getCurrentUser().get('userType') || [];
    }
    this.updateLink();
  }

  async capturePendingCall(): Promise<void> {
    const count = await this.getCountOfPendingCalls();
    if (count >= 30) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Already captured 30 calls', type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }

    if (!(await this.canPendingCallBeCaptured())) {
      this.broadcastError('Already captured');
      return;
    }

    this.captureCall();
  }

  private getRequestPayloadForPendingCallsCount(): RequestQueryPayload<Table.PendingCall> {
    return {
      where: {
        status: ApiClientConstant.PendingCall.Status.Requested,
        allocatedOperator: this.conn.getCurrentUser(),
      },
      include: ['user'],
      project: ['allocatedOperator'],
    };
  }

  private async getCountOfPendingCalls(): Promise<number> {
    const requestPayload = this.getRequestPayloadForPendingCallsCount();
    const count = await ApiConnector.count(Table.PendingCall, requestPayload);
    return count;
  }

  private async canPendingCallBeCaptured(): Promise<number> {
    const requestPayload: RequestQueryPayload<Table.PendingCall> = {
      where: { objectId: this.params.data.objectId, allocatedOperator: { $exists: false } },
      project: ['allocatedOperator'],
    };
    const count = await ApiConnector.count(Table.PendingCall, requestPayload);
    return count;
  }

  private async captureCall(): Promise<void> {
    try {
      const pendingCall = new Table.PendingCall();
      pendingCall.id = this.params.data.objectId;
      await pendingCall.save({ allocatedOperator: this.conn.getCurrentUser() });
      this.broadcastSuccess('Pending call captured');
      this.showPendingCallsAction = true;
      this.params.data.allocatedOperator = this.conn.getCurrentUser().toJSON();
      this.params.api.redrawRows({ rowNodes: [this.params.node] });
    } catch (error) {
      this.broadcastError(error.message);
    }
  }

  private broadcastError(message: string): void {
    this.broadcaster.broadcast('NOTIFY', { message, type: this.appConfig.Shared.Toast.Type.ERROR });
  }

  private broadcastSuccess(message: string): void {
    this.broadcaster.broadcast('NOTIFY', { message, type: this.appConfig.Shared.Toast.Type.SUCCESS });
  }

  updateLink(): void {
    if (this.params.data.type === ApiClientConstant.PendingCall.Type.ReacquisitionCall) {
      this.link = `/patient?username=${this.params.data.user?.username}`;
      return;
    }
    if (this.userTypes?.includes('SALES')) {
      this.link = `/newSales?username=${this.params.data.user?.username}`;
      return;
    }

    switch (this.params.data.type) {
      case ApiClientConstant.PendingCall.Type.UserRequestedCall: {
        this.link = `/ticket/${this.params.data.user.username}?ticketId=${this.params.data.supportTicket?.objectId}`;
        break;
      }
      case ApiClientConstant.PendingCall.Type.DoctorOrder:
      case ApiClientConstant.PendingCall.Type.InitialOrder:
      case ApiClientConstant.PendingCall.Type.OrderConfirmation:
      case ApiClientConstant.PendingCall.Type.WaitingForImage: {
        this.link = `/order/${this.params.data.referenceId}`;
        break;
      }
      case ApiClientConstant.PendingCall.Type.AbandonedCart: {
        this.link = `/cart/edit?username=${this.params.data.user.objectId}`;
        break;
      }
      case ApiClientConstant.PendingCall.Type.MissedCall:
      case ApiClientConstant.PendingCall.Type.AreYouOk:
      default: {
        if (!this.params.data.user?.objectId) {
          return;
        }
        this.link = `/chat/${this.params.data.user.objectId}`;
      }
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  async callUserThroughExotel(number: any): Promise<void> {
    try {
      const pendingCall = new Table.PendingCall();
      pendingCall.id = this.id;
      await this.conn.callThroughExotel(number, 'pendingCall', pendingCall);
      this.broadcaster.broadcast('NOTIFY', { message: 'Connecting', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Unable to connect to exotel', type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  openRecentPendingCallHistory(): void {
    this.dialog.open(PendingCallRecentHistoryComponent, { width: '95%', data: { userId: this.params.data.user.objectId } });
  }

  markCallCompleted(): void {
    let teenager = false;
    if (this.params.data.user.tags?.includes(ApiClientConstant.User.Tags.TEENAGER)
      || (this.params.data.user.age || 0) < 18) {
      teenager = true;
    }
    this.dialog
      .open(PendingCallCompleteComponent, {
        data: {
          objectId: this.params.value,
          type: this.params.data.type,
          referenceId: this.params.data.referenceId,
          referenceMessage: this.params.data.referenceMessage,
          languagePreference: this.params.data.languagePreference,
          teams: this.params.data.teams,
          teenager,
          userId: this.params.data.user.objectId,
          username: this.params.data.user.username,
        },
      })
      .afterClosed()
      .subscribe(async (result: boolean) => {
        if (!result) {
          return;
        }
        this.completed = true;
      });
  }
}
