import { MorningAndNightProductType } from '@cureskin/api-client/src/table/regimen';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ApiConnector, RequestQueryPayload, Table } from 'api-client';
import * as moment from 'moment';
import { ConnectionService } from '../../services/connection-service';
import { AppConfig } from '../../app/app.config';
import { ProductInstructionSelectionModal } from './product-instruction-selection-popup';
import { Broadcaster } from '../broadcaster';
import { LocalStorage } from '../../services/local-storage-service';
import { DataUploadModal } from '../modal/dataUploadModal';
import { HelperService } from '../../services/helper-service';

interface FollowUpSopChange {
  type: string;
  position: number;
  partOfDay: string;
  instruction: any;
  productId: string;
}

@Component({
  selector: 'regimen-products-edit',
  templateUrl: './regimen-products-edit.html',
  styleUrls: ['./regimen-products-edit.scss'],
})
export class RegimenProductsEditComponent {
  @Input('disableEdit') disableEdit: boolean;
  @Input('orderApprovalPage') orderApprovalPage: boolean;
  @Input('isEditAllowed') isEditAllowed: boolean;
  @Output('afterSaveRegimen') afterSaveRegimen: EventEmitter<any> = new EventEmitter();
  @Output() isRegimenSelected: EventEmitter<any> = new EventEmitter();
  @Input('regimenList') regimenList: Array<any>;
  @Input('regimen') regimen: any;
  public autoCompleteProductController: UntypedFormControl = new UntypedFormControl();
  productOptions: Observable<Array<{ name: string; object: any }>>;
  morningProducts: Array<any> = [];
  originalMorningProducts: Array<MorningAndNightProductType> = [];
  nightProducts: Array<any> = [];
  originalnightProducts: Array<MorningAndNightProductType> = [];
  regimenChange: any = {};
  isRegimenSave: boolean = false;
  isSelectedRegimen: boolean;
  showRegimenEditButton: boolean = true;
  changedProductsWhichAreAlreadyInOrdersInTransit: any = [];
  changedProductsWhichAreAlreadyInOrdersInDelivered: any = [];
  selectedSopRegimen: any;
  @Input('npdExperimentEnabled') npdExperimentEnabled: boolean;
  @Input('medicalNoteAdded') medicalNoteAdded: boolean;
  constructor(public appConfig: AppConfig,
    private dialog: MatDialog,
    private broadcaster: Broadcaster,
    private localStorage: LocalStorage,
    private connectionService: ConnectionService,
    private helper: HelperService) {}

  async ngOnInit(): Promise<void> {
    this.productOptions = this.autoCompleteProductController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getProducts(token)));
    await this.updateRegimenDetails();
    this.regimenList = this.regimenList?.filter((regimen: any, index: number): any => regimen.id !== this.regimen.id);
    if (this.regimenList?.length === 1) {
      await this.onChangeRegimen(this.regimenList[0]);
    }
  }

  async updateRegimenDetails(): Promise<void> {
    if (this.orderApprovalPage && this.regimenList?.length === 1) return;
    const where: any = {};
    if (this.regimen.get('regimenId')) {
      where.regimenId = this.regimen.get('regimenId');
    } else {
      where.objectId = this.regimen.id;
    }
    const regimen = await this.connectionService.findOneRegimen({
      where,
      include: [
        'products',
        'morning.product' as 'morning',
        'night.product' as 'night',
        'morning.product.instructions.frequencyOfApplicationLanguageString' as 'morning',
        'night.product.instructions.frequencyOfApplicationLanguageString' as 'night',
        'morning.product.instructions.areaOfApplicationLanguageString' as 'morning',
        'night.product.instructions.areaOfApplicationLanguageString' as 'night',
        'morning.product.instructions.quantityUnitLanguageString' as 'morning',
        'night.product.instructions.quantityUnitLanguageString' as 'night',
        'morning.product.instructions.durationOfApplicationLanguageString' as 'morning',
        'night.product.instructions.durationOfApplicationLanguageString' as 'night',
        'morning.instructionSet.frequencyOfApplicationLanguageString' as 'morning',
        'night.instructionSet.frequencyOfApplicationLanguageString' as 'night',
        'morning.instructionSet.areaOfApplicationLanguageString' as 'morning',
        'night.instructionSet.areaOfApplicationLanguageString' as 'night',
        'morning.instructionSet.quantityUnitLanguageString' as 'morning',
        'night.instructionSet.quantityUnitLanguageString' as 'night',
        'morning.instructionSet.durationOfApplicationLanguageString' as 'morning',
        'night.instructionSet.durationOfApplicationLanguageString' as 'night',
      ],
      project: ['morning', 'night', 'products'],
    });
    this.regimen.set('morning', regimen.get('morning'));
    this.regimen.set('night', regimen.get('night'));
    this.regimen.set('products', regimen.get('products'));
    this.morningProducts = [...this.regimen.get('morning')];
    this.originalMorningProducts = this.helper.deepCopy(regimen.get('morning'));
    this.nightProducts = [...this.regimen.get('night')];
    this.originalnightProducts = this.helper.deepCopy(regimen.get('night'));

    this.regimenChange.concernsLanguageString = this.regimen?.get('concernsLanguageString');
    this.regimenChange.originalProducts = [
      ...this.regimen.get('morning').map((morningProduct: any) => ({ ...morningProduct })),
      ...this.regimen.get('night').map((nightProduct: any) => ({ ...nightProduct })),
    ];
  }

  addNewProduct(partOfDay: 'morning' | 'night'): void {
    if (!this.checkRegimenEditAllowedUnderNPD()) return;
    const partOfDayName = `${partOfDay}Products`;
    this[partOfDayName].push({});
  }

  removeProduct(index: number, partOfDay: 'morning' | 'night'): void {
    if (!this.checkRegimenEditAllowedUnderNPD()) return;
    const partOfDayName = `${partOfDay}Products`;
    this[partOfDayName].splice(index, 1);
  }

  editProductInstruction(index: number, partOfDay: 'morning' | 'night'): void {
    if (!this.checkRegimenEditAllowedUnderNPD()) return;
    const partOfDayName = `${partOfDay}Products`;
    const dialogRef = this.dialog.open(ProductInstructionSelectionModal, {
      width: '95%',
      data: { product: this[partOfDayName][index].product,
        selectedInstructionCreationId: this[partOfDayName][index]?.instructionSet?.creationId,
        partOfDay: partOfDay.toUpperCase() },
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (!result?.selectedInstructionCreationId) {
        this.broadcaster.broadcast('NOTIFY', { message: 'Instruction not chosen', type: this.appConfig.Shared.Toast.Type.ERROR });
        return;
      }
      this[partOfDayName][index].product = result.product;
      const instructionSet = this[partOfDayName][index].product.get('instructions')
        .find((instruction: any) => instruction.creationId === result.selectedInstructionCreationId);
      this[partOfDayName][index].instructionSet = instructionSet;
      this[partOfDayName][index].productName = this.getProductDisplayName(result.product);
      this[partOfDayName][index].purposeDescriptionLanguageString = result.product.get('purposeDescriptionLanguageString');
      this.autoCompleteProductController.setValue('');
    });
  }

  autoCompleteOnProductSelect(item: { name: string; object: any }, partOfDay: 'morning' | 'night', index: number): void {
    const partOfDayName = `${partOfDay}Products`;
    this[partOfDayName][index].product = item.object;
    this[partOfDayName][index].productName = item.name;
    this[partOfDayName][index].purposeDescriptionLanguageString = item.object.get('purposeDescriptionLanguageString');
    this.editProductInstruction(index, partOfDay);
  }

  async getProducts(name: string): Promise<Array<{ name: string; object: any }>> {
    const payload: RequestQueryPayload<Table.Catalog> = {
      where: {
        title: { $regex: name, $options: 'i' },
        inventoryStatus: ['AVAILABLE', 'UNAVAILABLE', 'RESTRICTED'],
      },
      include: ['instructions.frequencyOfApplicationLanguageString' as 'instructions',
        'instructions.frequencyOfApplicationLanguageString' as 'instructions',
        'instructions.areaOfApplicationLanguageString' as 'instructions',
        'instructions.areaOfApplicationLanguageString' as 'instructions',
        'instructions.quantityUnitLanguageString' as 'instructions',
        'instructions.quantityUnitLanguageString' as 'instructions'],
      project: ['title', 'quantity', 'quantityUnit', 'type', 'margUnit', 'mrp', 'price', 'instructions',
        'purposeDescriptionLanguageString', 'isRepair'],
      ascending: 'margUnit',
      limit: 10,
    };
    const userRoles = this.localStorage.getJsonValue('userRoles') || [];
    if (!userRoles.includes('adminDoctor')) {
      payload.where.mrp = { $ne: 0 };
    }
    const products = await this.connectionService.findCatalogs(payload);
    return products.map((product: any): { name: string; object: any } => ({
      name: this.getProductDisplayName(product),
      object: product,
    }));
  }

  getProductDisplayName(product: any): string {
    return `${product.get('title')} [ ${
      (product.get('margUnit') || 1) > 1
        ? `${product.get('margUnit')} sheets, `
        : ''
    }${product.get('quantity')}${product.get('quantityUnit')} ] ${product.get('type') === 'sample' ? '(sample)' : ''}`;
  }

  async saveRegimen(): Promise<void> {
    if (!this.regimen) return;
    this.regimen.set('morning', this.morningProducts);
    this.regimen.set('night', this.nightProducts);
    const regimenValidity: { valid: boolean, errorMessage?: string} = await this.checkCurrentRegimenIsValid();
    if (!regimenValidity.valid) {
      await Promise.reject(new Error(regimenValidity.errorMessage));
    }
    await this.regimen.save();
    this.afterSaveRegimen.emit(this.regimen);
  }

  async checkCurrentRegimenIsValid(): Promise<{ valid: boolean, errorMessage?: string}> {
    // should not contain empty products in regimen
    if (this.doesContainEmptyObject(this.regimen.get('morning')) || this.doesContainEmptyObject(this.regimen.get('night'))) {
      return { valid: false, errorMessage: 'Product can not be empty in any regimen' };
    }

    // should check for product limit
    const uniqueProducts = this.findUniqueProducts(this.regimen);
    if (['variant_1999', 'variant_4999', 'variant_2999'].includes(this.regimen?.get('selectedVariantId'))
      && uniqueProducts.length !== 5 && !this.regimen.get('active')) {
      this.broadcaster.broadcast('NOTIFY', {
        message: '5 products to be sent for the current variant',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return { valid: false, errorMessage: 'Regimen should have 5 products' };
    }

    // check instructions are valid or not
    const checkInstructionsAreValid = await this.checkInstructionsAreValid();
    if (!checkInstructionsAreValid) {
      return { valid: false, errorMessage: 'Two or more alternate day products have same days of application' };
    }

    // check for display concern changed after changing repair product
    const isRepairProductEditedButDisplayConcernNotChanged = await this.isRepairProductEditedButDisplayConcernNotChanged();
    if (!isRepairProductEditedButDisplayConcernNotChanged) {
      return { valid: false, errorMessage: 'Display Concern not changed' };
    }
    return { valid: true };
  }

  doesContainEmptyObject(regimens: Array<any>): boolean {
    return !!regimens.filter((value: any) => Object.keys(value).length === 0).length;
  }

  async checkChangedProductsHasAlreadyInOrder(): Promise<void> {
    try {
      const startDate = moment().subtract(15, 'day').startOf('day').add(6, 'hours')
        .toDate();
      const endDate = moment().startOf('day').add(1, 'day').toDate();
      const changedProducts = this.getChangedProductsInCurrentRegimen();
      const where: any = {};
      where.user = this.regimen.get('forUser');
      where.createdAt = { $gte: startDate, $lte: endDate };
      where.isPartialOrder = false;
      where.products = { $in: [...changedProducts] };
      where.stage = { $nin: ['RETURN_INITIATED',
        'RETURN_REQUESTED',
        'ONLINE_PAYMENT_PENDING',
        'ONLINE_PAYMENT_SUCCESS',
        'INITIAL',
        'RETURNED',
        'CANCELED',
        'LOST_IN_TRANSIT'] };
      const orders = await this.connectionService.findOrders({ where, project: ['products', 'stage'] });
      if (!orders.length) return;
      orders.forEach((order:any) => {
        this.segregateDeliveredAndInTransitOrders(order, changedProducts);
      });
    } catch (error) {
      this.changedProductsWhichAreAlreadyInOrdersInDelivered = [];
      this.changedProductsWhichAreAlreadyInOrdersInTransit = [];
    }
  }

  async segregateDeliveredAndInTransitOrders(order: any, changedProducts: Array<any>): Promise<void> {
    const changedProductsIds: Array<string> = changedProducts.map((product: any) => product.id);
    // todo complete this function
    order.get('products').forEach((product: any) => {});
  }

  getChangedProductsInCurrentRegimen(): Array<any> {
    const regimenMorningProduct = this.regimen.get('morning').map((product:any) => product.product.get('margId'));
    const regimenNightProduct = this.regimen.get('night').map((product:any) => product.product.get('margId'));
    const editedRegimenAllProductIds:any = [...regimenMorningProduct, ...regimenNightProduct];
    const oldRegimenAllProductIds:any = this.regimen.get('products').map((product:any) => product.product.margId);
    return editedRegimenAllProductIds.filter((item:any) => !oldRegimenAllProductIds.includes(item));
  }
  async checkInstructionsAreValid(): Promise<boolean> {
    const productsInRegimen: Array<any> = this.regimen.get('morning').concat(this.regimen.get('night'));
    const map = {};
    const whenToApply = productsInRegimen
      .map((item: any) => {
        if (!item.instructionSet.frequencyOfApplicationLanguageString?.get('en')) return null;
        return item.instructionSet.frequencyOfApplicationLanguageString.get('en').split(',');
      })
      .filter((item: any) => item !== null)
      .filter((item: any) => item.includes('Monday') || item.includes('Tuesday'));
    const isDuplicate: boolean = whenToApply.some((item: any): boolean => item.some((i: any) => {
      const bool = !!map[i];
      map[i] = true;
      return bool;
    }));
    if (isDuplicate) return confirm('Two or more alternate day products have same days of application. are you sure?');
    return true;
  }

  async isRepairProductEditedButDisplayConcernNotChanged(): Promise<boolean> {
    if (!this.regimenChange?.originalProducts) return true;
    let isRepairProductsChanged = false;
    const repairProductsIdsBeforeChangeRegimen = [];
    const repairProductsIdsAfterChangeRegimen = [];
    this.regimenChange.originalProducts.forEach((product: any) => {
      if (product?.product?.get('isRepair')) {
        repairProductsIdsBeforeChangeRegimen.push(product.product.id);
      }
    });
    [...this.regimen.get('morning'), ...this.regimen.get('night')].forEach((product: any) => {
      if (product?.product?.get('isRepair')) {
        repairProductsIdsAfterChangeRegimen.push(product.product.id);
      }
    });
    repairProductsIdsBeforeChangeRegimen.forEach((productId: string) => {
      if (!repairProductsIdsAfterChangeRegimen.includes(productId)) {
        isRepairProductsChanged = true;
      }
    });
    if ((isRepairProductsChanged || repairProductsIdsBeforeChangeRegimen.length !== repairProductsIdsAfterChangeRegimen.length)
      && this.isSameArrayOfParseObject(this.regimen.get('concernsLanguageString'), this.regimenChange.concernsLanguageString)) {
      return confirm('Are you sure to continue without changing display concern?');
    }
    return true;
  }

  private isSameArrayOfParseObject(parseObjectArray1: Array<any> = [], parseObjectArray2: Array<any> = []): boolean {
    if (parseObjectArray1.length !== parseObjectArray2.length) {
      return false;
    }
    return parseObjectArray1.every((item: any, index: number) => (item.id === parseObjectArray2[index].id));
  }

  async saveRegimenOrderApproval(): Promise<void> {
    if (!this.regimen) return;
    this.regimen.set('morning', this.morningProducts);
    this.regimen.set('night', this.nightProducts);
    this.disableFollowUpSopOnRegimenEdit();
    await this.regimen.save();
    this.isRegimenSave = true;
    this.afterSaveRegimen.emit(this.regimen);
    this.broadcaster.broadcast('NOTIFY', { message: 'Regimen Saved', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    // this.disableEdit = true;
  }

  private disableFollowUpSopOnRegimenEdit(): void {
    if (!this.selectedSopRegimen) return;
    if (this.areProductsSame(this.selectedSopRegimen.get('morning'), this.morningProducts)
      && this.areProductsSame(this.selectedSopRegimen.get('night'), this.nightProducts)) {
      this.regimen.set('disableFollowUpSop', false);
      return;
    }
    this.regimen.set('disableFollowUpSop', true);
  }

  private areProductsSame(oldProducts: Array<any>, newProducts: Array<any>): boolean {
    if (oldProducts.length !== newProducts.length) {
      return false;
    }
    let productsMatched = true;
    oldProducts.forEach((productWithInstruction: any, index: number) => {
      if (productWithInstruction.product?.id !== newProducts[index].product.id
        || productWithInstruction.creationId !== newProducts[index].creationId) {
        productsMatched = false;
      }
    });
    return productsMatched;
  }
  moveProduct(event: CdkDragDrop<string[]>): void {
    if (this.disableEdit) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Edit Not Allowed',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    if (!this.checkRegimenEditAllowedUnderNPD()) return;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  onEditRegimen(): void {
    this.openEditPopUp();
  }
  async openEditPopUp(): Promise<void> {
    const editReason = this.dialog.open(DataUploadModal,
      { data: {
        title: 'Reason for editing regimen',
        minCharLength: 10,
      } });
    editReason.afterClosed().subscribe((result: any) => {
      if (!result) {
        return;
      }
      if (result) {
        this.disableEdit = false;
        const reason = {
          editReason: 'OTHER',
          editDetails: result,
        };
        this.regimen.set('regimenEditReason', reason);
      }
    });
  }

  async copyRegimen(regimenId_: string): Promise<any> {
    let regimenId = regimenId_;
    try {
      if (regimenId.includes('personalized')) {
        await Promise.reject(new Error('Can\'t use personalized regimen to copy'));
      }
      if (regimenId.split('_').length === 1) {
        regimenId = `${this.regimen.get('regimenId').split('_')[0]}_${regimenId}`;
      }
      this.selectedSopRegimen = await this.connectionService.findOneRegimen({
        where: { regimenId },
        include: [
          'morning.product' as 'morning',
          'night.product' as 'night',
          'morning.product.instructions.frequencyOfApplicationLanguageString' as 'morning',
          'night.product.instructions.frequencyOfApplicationLanguageString' as 'night',
          'morning.product.instructions.areaOfApplicationLanguageString' as 'morning',
          'night.product.instructions.areaOfApplicationLanguageString' as 'night',
          'morning.product.instructions.quantityUnitLanguageString' as 'morning',
          'night.product.instructions.quantityUnitLanguageString' as 'night',
          'morning.product.instructions.durationOfApplicationLanguageString' as 'morning',
          'night.product.instructions.durationOfApplicationLanguageString' as 'night',
          'morning.instructionSet.frequencyOfApplicationLanguageString' as 'morning',
          'night.instructionSet.frequencyOfApplicationLanguageString' as 'night',
          'morning.instructionSet.areaOfApplicationLanguageString' as 'morning',
          'night.instructionSet.areaOfApplicationLanguageString' as 'night',
          'morning.instructionSet.quantityUnitLanguageString' as 'morning',
          'night.instructionSet.quantityUnitLanguageString' as 'night',
        ],
      });
      if (!this.selectedSopRegimen) await Promise.reject(new Error('No Regimen Found'));
      await this.copyFromRegimen(this.selectedSopRegimen);
    } catch (err) {
      alert(err.message || err);
    }
  }
  async copyFromRegimen(regimen: any): Promise<void> {
    const fieldsToSkip = [
      'regimenId',
      'class',
      'type',
      'objectId',
      'ACL',
      'endDate',
      'activeFromDate',
      'createdAt',
      'updatedAt',
      'active',
      'followUpConfig',
      'numberOfProductsLimit',
    ];
    if (this.regimen.has('forUser')) {
      fieldsToSkip.push('fixedPrice', 'fixedPriceMRP', 'invoiceTitle');
    }
    [].concat(Object.keys(JSON.parse(JSON.stringify(this.regimen))))
      .concat(Object.keys(JSON.parse(JSON.stringify(regimen))))
      .filter((key: string) => !fieldsToSkip.includes(key))
      .forEach((key: string) => this.regimen.set(key, regimen.get(key)));
    const currentRegimen = this.regimen;
    this.isRegimenSelected.emit(regimen.get('regimenId'));
    this.regimen = new Table.Regimen();
    this.regimen = currentRegimen;
    this.morningProducts = currentRegimen.get('morning');
    this.nightProducts = currentRegimen.get('night');
  }

  async onChangeRegimen(regimen: any): Promise<void> {
    const copyFromRegimenId = regimen.get('regimenId');
    this.isSelectedRegimen = true;
    setTimeout(async () => {
      await this.copyRegimen(copyFromRegimenId);
    }, 1000);
  }

  async onSaveRegimen(): Promise<any> {
    await this.saveRegimenOrderApproval();
  }

  async changeRegimenOnBasisOfTreatmentOutcome(changes: Array<FollowUpSopChange>): Promise<void> {
    this.morningProducts = this.regimen.get('morning').map((morningProduct: any) => ({ ...morningProduct }));
    this.nightProducts = this.regimen.get('night').map((nightProduct: any) => ({ ...nightProduct }));
    if (!changes?.length) return;
    await Promise.all(changes.map((each: any) => this.applyChangeInRegimen(each,
      this.morningProducts,
      this.nightProducts)));
  }

  async applyChangeInRegimen(change: FollowUpSopChange,
    morning: Array<any>, night: Array<any>): Promise<void> {
    switch (change.type) {
      case 'addProduct': {
        await this.applyChanges(change, morning, night, this.addProductInRegimen);
        break;
      }
      case 'removeProduct': {
        await this.applyChanges(change, morning, night, this.removeProductInRegimen);
        break;
      }
      case 'changeInstruction': {
        await this.applyChanges(change, morning, night, this.changeInstructionInRegimen);
        break;
      }
      default:
    }
  }

  async applyChanges(change: FollowUpSopChange,
    morning: Array<any>, night: Array<any>, callback: any): Promise<void> {
    switch (change.partOfDay) {
      case 'morning': {
        const updatedMorningProduct = await callback(change, morning);
        this.morningProducts = updatedMorningProduct;
        break;
      }
      case 'night': {
        const updatedNightProduct = await callback(change, night);
        this.nightProducts = updatedNightProduct;
        break;
      }
      default:
    }
  }

  addProductInRegimen = async (change_: FollowUpSopChange,
    partOfDayProducts: Array<any>): Promise<Array<any>> => {
    const change = change_;
    const product = await ApiConnector.findOne(Table.Catalog, {
      where: { objectId: change.productId },
      include: ['purposeLanguageString',
        'descriptionLanguageString',
        'instructions.frequencyOfApplicationLanguageString' as 'instructions',
        'instructions.frequencyOfApplicationLanguageString' as 'instructions',
        'instructions.areaOfApplicationLanguageString' as 'instructions',
        'instructions.areaOfApplicationLanguageString' as 'instructions',
        'instructions.quantityUnitLanguageString' as 'instructions',
        'instructions.quantityUnitLanguageString' as 'instructions'],
      project: [
        'title',
        'margUnit',
        'quantity',
        'quantityUnit',
        'type',
        'purpose',
        'purposeLanguageString',
        'descriptionLanguageString',
        'instructions'],
    });
    const instructionsId = [change.instruction.frequencyOfApplicationLanguageString.id,
      change.instruction.areaOfApplicationLanguageString.id,
      change.instruction.quantityUnitLanguageString.id];
    const instructions = await this.connectionService.findLanguageStrings({
      where: { objectId: instructionsId },
      project: ['en'],
    });
    change.instruction.frequencyOfApplicationLanguageString = instructions
      .find((each: any) => each.id === change.instruction.frequencyOfApplicationLanguageString.id);
    change.instruction.areaOfApplicationLanguageString = instructions
      .find((each: any) => each.id === change.instruction.areaOfApplicationLanguageString.id);
    change.instruction.quantityUnitLanguageString = instructions
      .find((each: any) => each.id === change.instruction.quantityUnitLanguageString.id);
    const productName = this.getProductDisplayName(product);
    const productWithInstruction = {
      productName,
      product,
      purpose: product.get('purpose') || product.get('purposeLanguageString')?.get('en'),
      purposeDescriptionLanguageString: product.get('descriptionLanguageString'),
      purposeDescription: product.get('descriptionLanguageString')?.get('en'),
      hideInPreview: false,
      instructionSet: change.instruction,
    };
    partOfDayProducts.splice(Number(change.position), 0, productWithInstruction);
    return partOfDayProducts;
    // tslint:disable-next-line:semicolon
  };

  changeInstructionInRegimen = async (change_: { type: string, position: number, partOfDay: string, instruction: any, productId: string },
    partOfDayProducts_: Array<any>): Promise<Array<any>> => {
    const change = change_;
    const partOfDayProducts = partOfDayProducts_;
    const productIndex = partOfDayProducts.findIndex((each: any) => each.product.id === change.productId);
    if (productIndex === -1) {
      this.regimen.set('disableFollowUpSop', true);
      this.broadcaster.broadcast('NOTIFY', { message: 'Error in updating Instruction',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return partOfDayProducts;
    }
    const instructionsId = [change.instruction.frequencyOfApplicationLanguageString.id,
      change.instruction.areaOfApplicationLanguageString.id,
      change.instruction.quantityUnitLanguageString.id];
    const instructions = await this.connectionService.findLanguageStrings({
      where: { objectId: instructionsId },
      project: ['en'],
    });
    change.instruction.frequencyOfApplicationLanguageString = instructions
      .find((each: any) => each.id === change.instruction.frequencyOfApplicationLanguageString.id);
    change.instruction.areaOfApplicationLanguageString = instructions
      .find((each: any) => each.id === change.instruction.areaOfApplicationLanguageString.id);
    change.instruction.quantityUnitLanguageString = instructions
      .find((each: any) => each.id === change.instruction.quantityUnitLanguageString.id);
    partOfDayProducts[productIndex].instructionSet = change.instruction;
    return partOfDayProducts;
    // tslint:disable-next-line:semicolon
  };

  removeProductInRegimen = (change: { type: string, partOfDay: string, instruction: any, productId: string },
    partOfDayProducts: Array<any>): Array<any> => {
    const productIndex = partOfDayProducts.findIndex((each: any) => each.product.id === change.productId);
    if (productIndex < 0) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Error in removing Product',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return partOfDayProducts;
    }
    partOfDayProducts.splice(productIndex, 1);
    return partOfDayProducts;
    // tslint:disable-next-line:semicolon
  };
  toggleRegimenActive(active: boolean): void {
    this.regimen.set('active', active);
  }

  checkRegimenEditAllowedUnderNPD(): boolean {
    if (this.npdExperimentEnabled && !this.medicalNoteAdded) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Patient is under NPD Experiment, please add a medical Note to edit regimen',
        type: this.appConfig.Shared.Toast.Type.ERROR });
    }
    return true;
  }

  private findUniqueProducts(regimen: Table.Regimen): Array<Table.Catalog> {
    const uniqueProducts = {};
    return [
      ...regimen.get('morning'),
      ...regimen.get('night'),
    ]
      .filter(({ product }: MorningAndNightProductType) => {
        const id = (product.id || (product as { objectId?: string; }).objectId);
        if (!product || uniqueProducts[id]) { return false; }
        uniqueProducts[id] = product;
        return true;
      })
      .map(({ product }: any) => {
        const id = (product.id || product.objectId);
        const catalog = new Table.Catalog();
        catalog.id = id;
        return catalog;
      });
  }
}
