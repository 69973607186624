
<div class="hbox">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="col-xs-12 list-header">
        <ul class="breadcrumb">
          <li><a routerLink="/products/catalogs/">Catalogs</a></li>
          <li>{{productObj?.get('title')}}</li>
        </ul>
        <div class="pull-right">
          <button class="btn-xs btn-primary-outline" routerLink="../../catalog/{{productObj.id}}/edit">Edit Main Product</button>
          <button class="btn-xs btn-primary-outline ml-3" routerLink="../../catalogs/new">New</button>
        </div>
      </div>
    </div>
    <div class="flex-content">
      <div class="phoneWrapper" *ngIf="iframeLink && articles">
        <div class="iphone-x">
          <iframe class="hbox" *ngIf="iframeLink" [src]="iframeLink" allowtransparency="allowtransparency"></iframe>
        </div>
      </div>
      <div class="col-xs-12 p-4 container">
        <div class="col-xs-12 flex mt-0">
          <label class="col-xs-3">Title</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('title')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-3">Common Addon</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('isCommonAddon') ? 'Yes' : 'No'}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-3">Prescription Required</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('prescriptionRequired') ? 'Yes' : 'No'}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-3">Saftey Profile</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8 bold-600" [ngClass]="{'clr-red': (productObj.get('safteyProfile') === appConfig.Shared.Catalog.SAFTEY_PROFILE_OPTION.LIMITED_DATA_ON_SAFTEY) ||  (productObj.get('safteyProfile') === appConfig.Shared.Catalog.SAFTEY_PROFILE_OPTION.CONTRAINDICATED_DURING_PREGNANCY_AND_BREASTFEEDING), 'clr-blue': (productObj.get('safteyProfile') === appConfig.Shared.Catalog.SAFTEY_PROFILE_OPTION.PREGNANCY_AND_BREASTFEEDING_SAFE) }">{{productObj.get('safteyProfile')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-3">Shop Tags</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('shopTags')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-3">Exclude Boost Regimen Tags</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('excludeBoostProductsTags')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-3">Boost Your Regimen Tags</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('boostYourRegimenTags')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-3">Inventory Status</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('inventoryStatus')}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="productObj.get('alternateProduct')">
          <label class="col-xs-3">Alternative Product</label>
          <div class="col pull-left">:</div><a class="col-xs-8" href="/products/catalog/{{productObj.get('alternateProduct').id}}" target="_blank">{{productObj.get('alternateProduct').get('title')}}</a>
        </div>
        <div class="col-xs-12 flex" *ngIf="productObj.get('reorderType')">
          <label class="col-xs-3">Reorder Type</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('reorderType')}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="productObj.get('isRepair')">
          <label class="col-xs-3">Repair</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('isRepair')}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="productObj.get('key_features')">
          <label class="col-xs-3">Key Features</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8 flex">
            <div class="m-r-xs" *ngFor="let key of productObj.get('key_features'); last as isLast">
              <language-view [languageStringObj]="key"></language-view><span *ngIf="!isLast">,</span>
            </div>
          </div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-3">Product Unboxed Images</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8 flex">
            <ng-container *ngIf="productObj.get('productUnboxedImage').length">
              <div class="m-r-xs" *ngFor="let image of productObj.get('productUnboxedImage');"><a href="{{image}}" target="_blank"><img class="img-thumbnail" src="{{image}}" style="height: 150px;"/></a></div>
            </ng-container>
            <ng-container *ngIf="productObj.get('productUnboxedImage').length === 0">
              <div class="m-r-xs" *ngFor="let image of productObj.get('rebrandedImageWithoutBackground');"><a href="{{image}}" target="_blank"><img class="img-thumbnail" src="{{image}}" style="height: 150px;"/></a></div>
            </ng-container>
          </div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-3">Description</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-5">
            <language-view id="wordBrk" [languageStringObj]="productObj.get('descriptionLanguageString')"></language-view>
          </div>
        </div>
        <div class="col-xs-12 flex" *ngIf="productObj.get('ingredientsForExternalUsersLanguageString')?.length">
          <label class="col-xs-3">Ingredients Visible To External Users</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">
            <language-view [languageStringObj]="productObj.get('ingredientsForExternalUsersLanguageString')"></language-view>
          </div>
        </div>
        <div class="col-xs-12 flex" *ngIf="productObj.get('ingredientsForInternalUsersLanguageString')?.length">
          <label class="col-xs-3">Ingredients Visible To Internal Users</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">
            <language-view [languageStringObj]="productObj.get('ingredientsForInternalUsersLanguageString')"></language-view>
          </div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-3">Weight/Volume</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('quantity')}} {{productObj.get('quantityUnit')}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="productObj.get('sellers')">
          <label class="col-xs-3">Sellers</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8" style="overflow-x:scroll">
            <div class="label label-default m-l-xs m-t-xs pointer" *ngFor="let scheduler of productObj.get('sellers');">{{seller.get('Name')}}</div>
          </div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-3">Selling Price(inc. tax)</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('price')}} Rs.</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="productObj.get('mrp')">
          <label class="col-xs-3">MRP</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('mrp')}} Rs.</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-3">Effective Cost Price</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('effectiveCostPrice') ? (productObj.get("effectiveCostPrice") + ' Rs.') : ('ECP is not set for this product')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-3">External effective Cost Price</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('externalEffectiveCostPrice') ? (productObj.get("externalEffectiveCostPrice") + ' Rs.') : ('XECP is not set for this product')}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="productObj.get('tax')">
          <label class="col-xs-3">CGST%</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get("tax").cgst }}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="productObj.get('tax')">
          <label class="col-xs-3">SGST%</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('tax').sgst}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="productObj.get('purpose')">
          <label class="col-xs-3">Purpose</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('purpose')}}</div>
        </div>
        <div class="col-xs-12" *ngIf="productObj.get('ingredients')">
          <div class="col-xs-12 flex" *ngIf="productObj.get('ingredients').keyActive">
            <label class="col-xs-3">keyActive</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{productObj.get('ingredients').keyActive}}</div>
          </div>
          <div class="col-xs-12 flex" *ngIf="productObj.get('ingredients').supportingActive">
            <label class="col-xs-3">supportingActive</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{productObj.get('ingredients').supportingActive}}</div>
          </div>
          <div class="col-xs-12 flex" *ngIf="productObj.get('ingredients').baseIngredients">
            <label class="col-xs-3">baseIngredients</label>
            <div class="col pull-left">:</div>
            <div class="col-xs-8">{{productObj.get('ingredients').baseIngredients}}</div>
          </div>
        </div>
        <div class="col-xs-12 flex" *ngIf="productObj.get('purposeDescription')">
          <label class="col-xs-3">Purpose Description</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('purposeDescription')}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="productObj.get('vendorName')">
          <label class="col-xs-3">Vendor Name</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('vendorName')}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="productObj.get('ingredient')">
          <label class="col-xs-3">Ingredient</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('ingredient')}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="productObj.get('marketEquivalentName')">
          <label class="col-xs-3">Market Equivalent Name</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8">{{productObj.get('marketEquivalentName')}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="restrictions">
          <label class="col-xs-3">Restrictions</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8" *ngFor="let item of restrictions">{{item.get('en')}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="sideEffects">
          <label class="col-xs-3">Side Effects</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8" *ngFor="let item of sideEffects">{{item.get('en')}}</div>
        </div>
        <div class="col-xs-12 flex" *ngIf="indicationRestrictions">
          <label class="col-xs-3">Indication Restrictions</label>
          <div class="col pull-left">:</div>
          <div class="col-xs-8" *ngFor="let item of indicationRestrictions">{{item.get('en')}}</div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-3">Variants<i class="fas fa-plus-square ml-3" (click)="addCatalog('VARIANT')"></i></label>
          <div class="col pull-left">:</div>
          <div class="col-xs-9 scroll">
            <div class="card variant-card" *ngFor="let each of variantObj">
              <div class="col-xs-12">
                <label class="col-xs-5">SP</label>
                <div class="col-xs-1">:</div>
                <div class="col-xs-6">{{each.get('price')? each.get('price')+" Rs" : '-' }}</div>
              </div>
              <div class="col-xs-12">
                <label class="col-xs-5">MRP</label>
                <div class="col-xs-1">:</div>
                <div class="col-xs-6">{{each.get('mrp')? each.get('mrp')+" Rs" : '-' }}</div>
              </div>
              <div class="col-xs-12">
                <label class="col-xs-5">ECP</label>
                <div class="col-xs-1">:</div>
                <div class="col-xs-6">{{each.get('effectiveCostPrice')? each.get('effectiveCostPrice')+" Rs" : '-' }}</div>
              </div>
              <div class="col-xs-12">
                <label class="col-xs-5">WEIGHT</label>
                <div class="col-xs-1">:</div>
                <div class="col-xs-6">{{each.get('quantity')? each.get('quantity')+" "+each.get('quantityUnit'):'-'}}</div>
              </div>
              <div class="variant-edit shadow bg-secondary"><i class="far fa-edit" (click)="openCatalogVariantModal(each)"></i></div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-3">Samples<i class="fas fa-plus-square ml-3" (click)="addCatalog('SAMPLE')"></i></label>
          <div class="col pull-left">:</div>
          <div class="col-xs-9 scroll">
            <div class="card variant-card" *ngFor="let each of sampleObj; let i = index;">
              <div class="col-xs-12">
                <label class="col-xs-5">SP</label>
                <div class="col-xs-1">:</div>
                <div class="col-xs-6">{{each.get('price')? each.get('price')+" Rs" : '-' }}</div>
              </div>
              <div class="col-xs-12">
                <label class="col-xs-5">MRP</label>
                <div class="col-xs-1">:</div>
                <div class="col-xs-6">{{each.get('mrp')? each.get('mrp')+" Rs" : '-' }}</div>
              </div>
              <div class="col-xs-12">
                <label class="col-xs-5">WEIGHT</label>
                <div class="col-xs-1">:</div>
                <div class="col-xs-6">{{each.get('quantity')? each.get('quantity')+" "+each.get('quantityUnit'):'-'}}</div>
              </div>
              <div class="variant-edit shadow bg-secondary"><i class="far fa-edit" (click)="openCatalogVariantModal(each)"></i></div>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <catalog-instruction [parseObj]="productObj" name="instructions" [title]="productObj?.get('title')"></catalog-instruction>
        </div>
        <div class="col-xs-12 flex">
          <label class="col-xs-3">Show Inactive</label>
          <label class="i-switch i-switch-sm bg-success dker m-t-xs" style="top: 3px;">
            <input class="ng-pristine ng-valid ng-touched" type="checkbox" [(ngModel)]="showInactive" name="showInactive"/><i></i>
          </label>
        </div>
      </div>
      <div class="col-xs-12">
        <mat-accordion class="pb-2">
          <mat-expansion-panel class="pb-2" (opened)="regimenPanelOpen = true" (closed)="regimenPanelOpen=false">
            <mat-expansion-panel-header>
              <mat-panel-title class="bold uppercase fontR">Regimens Having - {{productObj.get('title')}}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngFor="let regimen of regimens">
                <div class="col-xs-12 pl-3" *ngIf="!showInactive && regimen.get('active')" style="color:green;font-size:13px">
                  <div class="col-xs-2 pl-2">{{regimen.get("regimenId")}}</div>
                  <div class="col-xs-9">
                    <a class="text-info mr-2" routerLink="../../../products/regimen/{{regimen.get('regimenId')}}" target="_blank">{{regimen.get('title')}}</a>
                    <a class="fa fa-pencil-square-o mr-2" routerLink="../../../products/regimen/{{regimen.get('regimenId')}}/edit" target="_blank"></a>
                    <a class="fa fa-copy mr-2" routerLink="../../../products/regimen/{{regimen.get('regimenId')}}/edit" [queryParams]="{ copy: regimen.id}" target="_blank"></a>
                    <i class="fa fa-circle" *ngIf="regimen.get('active')"></i>
                  </div>
                </div>
                <div class="col-xs-12 pl-3" *ngIf="showInactive && !regimen.get('active')" style="color:green;">
                  <div class="col-xs-3 pl-2">{{regimen.get("regimenId")}}</div>
                  <div class="col-xs-9">
                    <a class="text-info mr-2" routerLink="../../../products/regimen/{{regimen.get('regimenId')}}" target="_blank">{{regimen.get('title')}}</a>
                    <a class="fa fa-pencil-square-o mr-2" routerLink="../../../products/regimen/{{regimen.get('regimenId')}}/edit" target="_blank"></a>
                    <a class="fa fa-copy mr-2" routerLink="../../../products/regimen/{{regimen.get('regimenId')}}/edit" [queryParams]="{ copy: regimen.id}" target="_blank"></a>
                    <i class="fa fa-circle" *ngIf="!regimen.get('active')" style="color:red;"></i></div>
                </div>
            </ng-container>
          </mat-expansion-panel>
          <mat-expansion-panel class="pb-2" (opened)="comboPanelOpen = true" (closed)="comboPanelOpen = false">
            <mat-expansion-panel-header>
              <mat-panel-title class="bold uppercase fontR">Combos Having - {{productObj.get('title')}}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngFor="let item of combos">
              <div class="col-xs-12 pl-3">
                <a class="text-info mr-2" routerLink="../../../products/catalog/{{item.id}}" target="_blank">{{item?.get('title')}}</a>
              </div>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
