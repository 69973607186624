import * as moment from 'moment';
import { NgZone, Component, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { ApiClientConstant, ApiConnector, RequestCountPayload, RequestQueryPayload, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { WindowRefService } from '../../../../services/window-ref-service';
import { HelperService } from '../../../../services/helper-service';
import { FilterConstants } from '../../../../components/filter-dropdown/filter-constants';
import { FilterType } from '../../../../../typings/client/filter';
import { AppConfig } from '../../../app.config';
import { ChangeAllocatedDoctor } from '../../../../components/change-allocated-doctor';
import { LocalStorage } from '../../../../services/local-storage-service';
import { Broadcaster } from '../../../../components/broadcaster';

@Component({
  selector: 'list',
  templateUrl: './list.html',
  styleUrls: ['./list.scss'],
})
export class ListComponent implements OnDestroy {
  gridApi: GridApi;
  dataSource: any;
  columnDefs: any;
  filters: any[] = [];
  userService: Array<string> = [];
  filterConfig: Array<FilterType.FilterConfigType> = [];
  components: any;
  followUps: any;
  moment: any;
  autoRefresh: boolean = true;
  autoRefreshInterval: any;
  count: number = 0;
  ui: any;
  isDoctorReallocationAllowed: boolean = false;
  changingFollowupDoctorList: Array<any> = [];
  languageFilter: Array<string> = [];
  type: string;
  currentUser: any;
  isDoctor: boolean;
  isCurrentUserAdmin: boolean;
  adminToggle: boolean = true;
  internalTeamFilter: Array<any> = [];
  resetInternalTeamFilter: boolean;

  constructor(private route: ActivatedRoute,
    private zone: NgZone,
    private conn: ConnectionService,
    public helper: HelperService,
    public appConfig: AppConfig,
    private dialog: MatDialog,
    private storage: LocalStorage,
    private broadcaster: Broadcaster,
    private changeDetectorRef: ChangeDetectorRef,
    private windowRef: WindowRefService) {
    this.moment = moment;
  }

  toggleAutoRefresh(): void {
    if (this.autoRefresh) {
      this.reset();
      this.autoRefreshInterval = setInterval(() => this.reset(), 20000);
    } else if (this.autoRefreshInterval) clearInterval(this.autoRefreshInterval);
  }

  async ngOnInit(): Promise<void> {
    this.userService = this.storage.getValue('userRoles');
    this.isCurrentUserAdmin = this.userService.includes('admin') || this.userService.includes('adminDoctor');
    this.filterConfig = [{
      key: 'followUpReason',
      type: FilterConstants.DATA_TYPE.FIXED,
      value: ['New Product added to regimen', 'Regular'],
    }];
    this.currentUser = this.conn.getCurrentUser();
    if ((this.currentUser.get('userType') || []).includes('MBBS')) {
      this.type = 'mbbs';
    }
    if ((this.currentUser.get('userType') || []).includes('DERMATOLOGIST')) {
      this.type = 'dermatologist';
    }
    if (this.userService.includes('admin') || this.userService.includes('adminDoctor')) {
      delete this.type;
    }
    if (this.currentUser.get('type') === ApiClientConstant.User.Type.DOCTOR) {
      this.isDoctor = true;
    }
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.isDoctorReallocationAllowed = this.storage.getJsonValue('userRoles').includes('operatorAndDoctorAllocator');
    this.setUpGrid();
    this.resetFilters();
    this.toggleAutoRefresh();
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) {
              this.gridApi.showNoRowsOverlay();
            } else {
              this.gridApi.hideOverlay();
            }
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Name',
      field: 'user.PatientName',
      pinned: 'left',
      cellRenderer: (params_: any): any => {
        const params = params_;
        if (!params.data) {
          const eDiv = this.windowRef.nativeWindow.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        if (!params.data.doctorConfirmed) {
          const eDiv = this.windowRef.nativeWindow.document.createElement('div');
          const followUp = params.data;
          eDiv.addEventListener('click', async () => {
            if (!this.isDoctor || this.isCurrentUserAdmin) {
              this.windowRef.nativeWindow.open(`/patient?username=${params.data.user.username}`, '_blank');
              return;
            }
            if (params.data.followUpCount === 1 && !this.currentUser.get('userType').includes(ApiClientConstant.User.UserType.MBBS)) {
              this.broadcaster.broadcast('NOTIFY', {
                message: 'This is first followup you can\'t capture it',
                type: this.appConfig.Shared.Toast.Type.ERROR,
              });
              return;
            }
            if (params.data.followUpCount === 2
              && !this.currentUser.get('userType').includes(ApiClientConstant.User.UserType.DERMATOLOGIST)) {
              this.broadcaster.broadcast('NOTIFY', {
                message: 'This is second followup you can\'t capture it',
                type: this.appConfig.Shared.Toast.Type.ERROR,
              });
              return;
            }
            const requestPayload1: RequestQueryPayload<Table.FollowUp> = {
              where: { objectId: params.data.objectId },
              project: [
                'followUpCount',
                'doctorConfirmed',
              ],
            };
            const requestPayload2: RequestCountPayload<Table.FollowUp> = {
              where: {
                State: 'PENDING',
                ready: true,
                doctorConfirmed: true,
                allocatedDoctor: this.currentUser,
                effectiveFollowUpDate: { $lte: moment().subtract(20, 'minute').toDate() },
              },
            };
            let updatedFollowUp: any = '';
            let capturedFollowUp: number = 0;
            [updatedFollowUp, capturedFollowUp] = await Promise.all([
              ApiConnector.findOne(Table.FollowUp, requestPayload1),
              ApiConnector.count(Table.FollowUp, requestPayload2),
            ]);
            if (this.isCaptureLimitExceed(capturedFollowUp)) {
              this.broadcaster.broadcast('NOTIFY', {
                message: 'You have captured more than 5 cases',
                type: this.appConfig.Shared.Toast.Type.ERROR,
              });
              return;
            }
            if (updatedFollowUp.get('doctorConfirmed')) {
              this.broadcaster.broadcast('NOTIFY', {
                message: 'Followup already Taken By Other Doctor',
                type: this.appConfig.Shared.Toast.Type.ERROR,
              });
              this.gridApi.redrawRows({ rowNodes: [params.node] });
              return;
            }
            try {
              updatedFollowUp.set('doctorConfirmed', true);
              updatedFollowUp.set('allocatedDoctor', this.conn.getCurrentUser());
              updatedFollowUp.set('capturedAt', new Date());
              await updatedFollowUp.save();
              params.data.doctorConfirmed = true;
              this.gridApi.redrawRows({ rowNodes: [params.node] });
              this.windowRef.nativeWindow.open(`/patient?username=${params.data.user.username}`, '_blank');
            } catch (error) {
              this.broadcaster.broadcast('NOTIFY', {
                message: error.message || error,
                type: this.appConfig.Shared.Toast.Type.ERROR,
              });
            }
          });
          const buttonClass = 'btn-xs';
          eDiv.innerHTML = `<a><button class="${buttonClass}">CAPTURE</button></a>`;
          return eDiv;
        }
        let title: string = '';
        let styleClass: string = '';
        if (params.data.doctorCallNeeded || params.data.followupPhoneCall === 'Yes, I want a call back') {
          title = 'DoctorCall Needed';
          styleClass = 'list-label list-label-bg-red';
        }
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        let innerHTML = '<div class="flex">';
        innerHTML += `<a class='${styleClass} p-0 ellipsis'
            target="_blank" href='/patient?username=${params.data.user.username}' title='${title}'>${params.value}</a>`;
        innerHTML += '</div>';
        eDiv.innerHTML += innerHTML;
        return eDiv;
      },
      width: 170,
    }, {
      headerName: 'Effective FollowUpDate',
      field: 'effectiveFollowUpDate.iso',
      width: 165,
      cellRenderer: (params: any): any => {
        if (!params.value || (!params.data.doctorConfirmed && !this.isCurrentUserAdmin)) return '';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = moment(params.value).format('DD MMM YY, HH:mm A');
        return eDiv;
      },
    }, {
      headerName: 'Mobile Number',
      field: 'user.MobileNumber',
      width: 120,
      cellRenderer: (params: any): any => {
        if (!params.value || (!params.data.doctorConfirmed && !this.isCurrentUserAdmin)) return '';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = params.value;
        return eDiv;
      },
    }, {
      headerName: 'languagePreference',
      field: 'languagePreference',
      width: 70,
      cellRenderer: (params: any): any => {
        if (!params.value) return '';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = params.value;
        return eDiv;
      },
    }, {
      headerName: 'Doctor',
      field: 'allocatedDoctor',
      cellRenderer: (params: any): any => {
        if (!params.value || (!params.data.doctorConfirmed && !this.isCurrentUserAdmin)) return '';
        // if (params.value.username !== this.currentUser.get('username') && !this.isCurrentUserAdmin) return '';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        if (this.isDoctorReallocationAllowed) {
          eDiv.innerHTML = '<input style="min-height: unset !important;" type="checkbox"'
            + `[checked]="${this.changingFollowupDoctorList.includes(params.data.allocatedDoctor.objectId)}">`;
          eDiv.addEventListener('click', async () => {
            const index = this.changingFollowupDoctorList.indexOf(params.data.objectId);
            if (index === -1) {
              this.changingFollowupDoctorList.push(params.data.objectId);
            } else {
              this.changingFollowupDoctorList.splice(index, 1);
            }
          });
        } else {
          eDiv.className = 'mr-2';
        }
        eDiv.className = 'mr-2';
        eDiv.innerHTML += '&nbsp;&nbsp&nbsp';
        eDiv.innerHTML += params.value.DoctorDisplayName;
        return eDiv;
      },
      width: 150,
    }, {
      headerName: 'Last FollowUpAllocatedDoctor',
      field: 'user.objectId',
      width: 150,
      hide: !this.isCurrentUserAdmin,
      cellRenderer: (params: any): any => {
        if (!params.value || (params.data.followUpCount < 2)) return '';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        const user = new Table.User();
        user.id = params.value;
        ApiConnector.findOne(Table.FollowUp, {
          where: {
            user,
            followUpCount: params.data.followUpCount - 1,
            State: ApiClientConstant.FollowUp.State.FINISHED,
          },
          include: ['allocatedDoctor'],
          descending: 'createdAt',
          project: ['allocatedDoctor.DoctorDisplayName' as 'allocatedDoctor'],
        }).then((data: any) => {
          eDiv.innerHTML = data?.get('allocatedDoctor')?.get('DoctorDisplayName');
          return eDiv;
        });
        eDiv.innerHTML = '';
        return eDiv;
      },
    }, {
      headerName: 'followUpCount',
      field: 'followUpCount',
      width: 100,
      cellRenderer: (params: any): any => {
        if (!params.value || params.value !== 2) return '';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = params.value;
        return eDiv;
      },
      filter: true,
    }, {
      headerName: 'Doctor Count',
      field: 'doctorCount',
      width: 100,
      cellRenderer: (params: any): any => {
        if (!params.value || (!params.data.doctorConfirmed && !this.isCurrentUserAdmin)) return '';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = params.value;
        return eDiv;
      },
    }, {
      headerName: 'Regimen Count',
      field: 'regimenCount',
      width: 100,
      cellRenderer: (params: any): any => {
        if (!params.value || (!params.data.doctorConfirmed && !this.isCurrentUserAdmin)) return '';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = params.value;
        return eDiv;
      },
    }, {
      headerName: 'Notes',
      field: 'followUpReason',
      flex: 1,
      cellRenderer: (params: any): any => {
        if (!params.value || (!params.data.doctorConfirmed && !this.isCurrentUserAdmin)) return '';
        const eDiv = this.windowRef.nativeWindow.document.createElement('div');
        eDiv.innerHTML = `<div class='col-xs-12 no-padder ellipsis', title="${params.value}">${params.value}</div>`;
        return eDiv;
      },
    }];
  }

  reset(): void {
    if (!this.gridApi) {
      return;
    }
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  async loadMore(params: any): Promise<Array<any>> {
    const requestPayload: any = {
      where: {
        State: 'PENDING',
        ready: true,
        doctorConfirmed: false,
        // todo: change after api fix for followup delay
        effectiveFollowUpDate: { $lte: moment().subtract(20, 'minutes').toDate() },
      },
      limit: this.ui.grid.pageSize,
      skip: params.startRow,
      ascending: 'effectiveFollowUpDate',
      include: [
        'user',
        'allocatedDoctor',
        'allocatedOperator',
        'regimens',
        'regimens.products' as 'regimens',
      ],
      project: [
        'followUpReason',
        'followUpCount',
        'effectiveFollowUpDate',
        'doctorCount',
        'regimenCount',
        'allocatedDoctor.username' as 'allocatedDoctor',
        'allocatedOperator.username' as 'allocatedDoctor',
        'allocatedDoctor.DoctorDisplayName' as 'allocatedDoctor',
        'user.PatientName' as 'user',
        'user.MobileNumber' as 'user',
        'user.languagePreference' as 'user',
        'user.username' as 'user',
        'regimens.products.isTablet' as 'regimens',
        'regimens.products.isAntibiotic' as 'regimens',
        'regimens.active' as 'regimens',
        'doctorCallNeeded',
        'doctorConfirmed',
        'languagePreference',
        'followupPhoneCall',
      ],
    };
    if (this.isCurrentUserAdmin) {
      delete requestPayload.where.$or;
      delete requestPayload.where.doctorConfirmed;
    }
    if (this.languageFilter.length) {
      requestPayload.where.languagePreference = { $in: this.languageFilter };
    }
    if (this.type === 'dermatologist') {
      requestPayload.where.doctorConfirmed = false;
      requestPayload.where.allocatedTeam = ApiClientConstant.FollowUp.AllocatedTeam.DERMATOLOGIST;
      requestPayload.where.allocatedDoctor = this.internalTeamFilter;
      if (this.isCurrentUserAdmin) {
        delete requestPayload.where.doctorConfirmed;
        requestPayload.where.allocatedTeam = ApiClientConstant.FollowUp.AllocatedTeam.DERMATOLOGIST;
        requestPayload.where.$or = [
          { $and: [{ allocatedDoctor: this.internalTeamFilter }] }, { doctorConfirmed: { $ne: true } },
        ];
      }
    }
    if (this.type === 'mbbs') {
      requestPayload.where.doctorConfirmed = false;
      requestPayload.where.allocatedTeam = ApiClientConstant.FollowUp.AllocatedTeam.MBBS;
      requestPayload.where.allocatedDoctor = this.internalTeamFilter;
      if (this.isCurrentUserAdmin) {
        delete requestPayload.where.doctorConfirmed;
        requestPayload.where.allocatedTeam = ApiClientConstant.FollowUp.AllocatedTeam.MBBS;
        requestPayload.where.$or = [
          { $and: [{ allocatedDoctor: this.internalTeamFilter }] }, { doctorConfirmed: { $ne: true } },
        ];
      }
    }
    if (params.filterModel) {
      const keys = Object.keys(params.filterModel);
      keys.forEach((key: any) => {
        if (key === 'followUpCount') {
          requestPayload.where[key] = Number(params.filterModel[key].filter) + 1;
        } else {
          requestPayload.where[key] = params.filterModel[key].filter;
        }
      });
    }
    let data: Array<any>;
    data = await this.conn.findFollowUps(requestPayload)
      .then((result: Array<any>) => result.map((each: any) => {
        const eachJSON = each.toJSON();
        eachJSON.isAntibiotic = this.helper.isAntibioticRegimen(each.get('regimens'));
        return eachJSON;
      }));
    this.updateCount(requestPayload);
    const randomIndex = Math.floor(Math.random() * (((data.length / 2) + 10) - (data.length / 2)) + (data.length / 2));
    const tempData = data.splice(randomIndex, Math.min(10, (data.length - randomIndex)));
    data = [...tempData, ...data];
    let currentDoctorCases;
    if (params.startRow === 0 && this.currentUser.get('type') === ApiClientConstant.User.Type.DOCTOR) {
      requestPayload.where.doctorConfirmed = true;
      requestPayload.where.allocatedDoctor = this.currentUser;
      delete requestPayload.where.$or;
      delete requestPayload.where.languagePreference;
      currentDoctorCases = await this.conn.findFollowUps(requestPayload)
        .then((result: Array<any>) => result.map((each: any) => each.toJSON()));
    }
    if (currentDoctorCases?.length && params.startRow === 0) {
      this.count += currentDoctorCases.length;
      const updatedData = [...currentDoctorCases, ...data];
      return updatedData;
    }
    return data;
  }

  async updateCount(requestPayload: RequestQueryPayload<Table.PendingCall>): Promise<void> {
    this.count = await this.conn.countFollowUps(requestPayload);
    this.changeDetectorRef.detectChanges();
  }

  openAllocatedDoctorDialog(): void {
    const dialogRef = this.dialog.open(ChangeAllocatedDoctor, {
      data: { followUpList: this.changingFollowupDoctorList, type: this.appConfig.Shared.AllocatedDoctorPopup.Type.FOLLOWUP },
    });
    dialogRef.afterClosed().subscribe(async (data: any): Promise<any> => {
      if (!data) return;
      this.clearAllSelectedFollowUp();
    });
  }

  clearAllSelectedFollowUp(): void {
    this.changingFollowupDoctorList = [];
    this.reset();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  ngOnDestroy(): void {
    delete this.followUps;
    delete this.ui;
    delete this.route;
    delete this.zone;
    delete this.conn;
  }

  resetFilters(): void {
    this.filters = [];
    this.resetInternalTeamFilter = true;
    this.reset();
  }

  updateLanguageFilter(languageFilter: Array<any>): void {
    this.languageFilter = languageFilter;
    this.reset();
  }

  toggleSelection(type: string): void {
    if (this.type === type) {
      delete this.type;
    } else {
      this.type = type;
    }
    this.reset();
  }

  applyFilter(event: any): void {
  }

  toggleAdminStatus(): void {
    this.adminToggle = !this.adminToggle;
    if (!this.adminToggle) {
      this.isCurrentUserAdmin = false;
    } else {
      this.isCurrentUserAdmin = true;
    }
    this.reset();
  }

  updatedInternalTeamList(internalTeamFilter: Array<any>): void {
    this.internalTeamFilter = internalTeamFilter;
    this.resetInternalTeamFilter = false;
    this.reset();
  }

  private isCaptureLimitExceed(capturedFollowUpNumber: number): boolean {
    return (capturedFollowUpNumber >= 5 && this.currentUser.get('userType').includes(ApiClientConstant.User.UserType.MBBS))
    || (capturedFollowUpNumber >= 10 && this.currentUser.get('userType').includes(ApiClientConstant.User.UserType.DERMATOLOGIST));
  }
}
