
<div class="col-xs-12 p-0" *ngIf="!showNewUI">
  <div class="hbox" fillComponentParentView="fillComponentParentView">
    <div class="flex-box" *ngIf="order && user">
      <div class="flex-heading p-3 shdw" *ngIf="orderStage === apiClientConstant.Order.Stage.WAITING_FOR_IMAGE && order?.get('type') === apiClientConstant.Order.Type.REGIMEN">
        <button class="mr-3 small pull-right" (click)="markOrderWaitingForImage()">Images insufficient or incorrect</button>
      </div>
      <div class="flex-heading p-3 shdw" *ngIf="orderStage === apiClientConstant.Order.Stage.DOCTOR_APPROVAL_PENDING || orderStage === apiClientConstant.Order.Stage.CONSULTATION_CREATED">
        <div class="fontL2 bold text-cap pull-left"><span>{{order.get('type')}} ORDER</span><span class="pl-2" *ngIf="order.get('regimen')">-  {{order.get('regimen')?.get('class')}}</span><span class="pl-2">-  {{user.get('PatientName')}}/{{user.get('Age')}}/{{user.get('Gender')}}/{{userCommunicationLanguage}}/{{user.get('lastLocationCity')}},{{user.get('lastLocationState')}}</span><span class="pl-2 clr-red" *ngIf="user.get('marketingTags')?.includes('STEROID')">- STEROID</span><span class="alert-danger bold" *ngIf="regimen?.get('numberOfProductsLimit')"> This regimen should contain {{ regimen?.get('numberOfProductsLimit') }} products</span></div>
        <div class="pull-right flex" *ngIf="!order.get('doctorConfirmed')">
          <button class="mr-3 small bold" (click)="captureOrder()">Capture</button>
        </div>
        <div class="pull-right flex" *ngIf="order.get('doctorConfirmed')">
          <div class="col-xs-4 mr-3">
            <blocked-bot-assistant-modal [user]="user" [hideChatStatus]="true"></blocked-bot-assistant-modal>
          </div>
          <div class="pull-right flex" *ngIf="shouldCallUserBeforeApproval">
            <div class="alert-danger bold">User opted for call before order approval</div>
          </div>
          <div class="mobile call-icon col-xs-2"><em class="fas fa-phone-alt" (click)="callUserThroughExotel(user.get('MobileNumber'))" title="Mobile"></em><em class="fas fa-phone-alt alt-2 ml-3" *ngIf="user.get('alternateNumber')" (click)="callUserThroughExotel(user.get('alternateNumber'))" title="Alternate Mobile"></em></div>
          <button class="mr-3 small btn-danger bold" *ngIf="orderStage === apiClientConstant.Order.Stage.DOCTOR_APPROVAL_PENDING && order.get('regimen')?.get('optedForDoctorCall') && !callAlreadyDone">Opted for doctor call</button>
          <button class="mr-3 small btn-success bold" *ngIf="order.get('regimen')?.get('optedForDoctorCall') && callAlreadyDone">Call Already Done</button>
          <button class="mr-3 small btn-success bold" *ngIf="voiceNoteAlreadySent">voice Note Already sent</button>
          <button class="mr-3 small btn-danger bold" *ngIf="orderStage === apiClientConstant.Order.Stage.DOCTOR_APPROVAL_PENDING && order.get('regimen')?.get('optedForDoctorCall') === false">Opted for doctor Voice note</button>
          <button class="mr-3 small btn-secondary" (click)="cancelOrder(conifrmOrderCancelDialog)" [disabled]="disableActionButtons">Cancel Order</button>
          <button class="mr-3 small" (click)="markOrderWaitingForImage()">{{order?.get('type') === apiClientConstant.Order.Type.REGIMEN ? 'Images insufficient or incorrect' : 'Waiting For Image'}}</button>
          <button class="small" (click)="saveRegimenAndApprove()" [disabled]="disableActionButtons">{{ regimenDirty ? "Save And " : ""}} Approve</button>
        </div>
      </div>
      <div class="flex-content" #content>
        <div class="col-xs-12 py-3 text-center" *ngIf="waitingForImageConfirmation">
          <form #waitingForImageForm="ngForm">
            <label class="col-xs-12 bold">Why is the order moved to "Waiting For Image" ?</label>
            <div class="col-xs-9">
              <div class="col-xs-12 p-0 m-0">
                <mat-select [(ngModel)]="selectedWaitingForImageReason" name="selectedWaitingForImageReasons" placeholder="Select Reason(s)" [formControl]="formController" required="true" style="min-width:150px;padding:4px;outline:1px solid black;">
                  <mat-option *ngFor="let item of apiClientConstant.Order.WaitingForImageReasons" [value]="item.key">{{item.key}}</mat-option>
                </mat-select>
              </div>
            </div>
            <div class="col-xs-3">
              <button class="btn-primary" [disabled]="!waitingForImageForm.valid || !selectedWaitingForImageReason" (click)="confirmWaitingForImage()">Confirm</button>
            </div>
          </form>
        </div>
        <div class="col-xs-12 p-0 flex flex-wrap">
          <div class="col-md-4">
            <div class="col-xs-12 list-header flex justifySB fontL text-cap">
              <div class="pointer" (click)="toggleView('treeInteractions')" [ngClass]="{'clr-primary underline': viewType=='treeInteractions'}">Tree</div><i class="fa fa-history" (click)="openFollowUpModal(appConfig.FollowUp.PopupType.HISTORY_FOLLOWUP)"></i>
              <div class="pointer" (click)="toggleView('humanInteractions')" [ngClass]="{'clr-primary underline': viewType=='humanInteractions'}">Human</div>
              <div>
                <button class="btn-secondary pull-right small" *ngIf="user"><a href="/chat/{{user.id}}" target="_blank">Chat</a></button>
              </div>
            </div>
            <div class="col-xs-12">
              <div class="col-xs-12 p-0" *ngIf="viewType=='treeInteractions'" style="overflow-y:auto;height:400px;">
                <user-chat-view-summary [user]="user" [regimenClass]="regimen?.get('class')"></user-chat-view-summary>
              </div>
              <div class="col-xs-12 p-0" *ngIf="viewType=='humanInteractions' && regimen" style="overflow-y:auto;height:400px;">
                <user-chat-view-human-interaction [privateMainConcernClass]="regimen.get('class')" [user]="user" [height]="'400px'"></user-chat-view-human-interaction>
              </div>
            </div>
          </div>
          <div class="col-md-8" *ngIf="username">
            <div class="col-xs-12 list-header">InstantCheckup</div>
            <div class="col-xs-12 p-0" style="height: 400px;">
              <instant-checkup [username]="username" [hideFullImage]="true" [useAsSubComponent]="true"></instant-checkup>
            </div>
          </div>
        </div>
        <div class="col-xs-12 p-0" *ngIf="regimen && oldOrders.length">
          <div class="col-xs-12 list-header">Other Orders With Same Regimen Class</div>
          <div class="col-xs-12 p-4 orderList">
            <div class="col-xs-12 of-auto fontS" style="max-height:150px;">
              <div class="orderRow" *ngFor="let order of oldOrders;"><span class="pr-2" *ngIf="order.regimen">({{ order.regimen.class}})</span><span class="pr-2" title="Rs {{order.amount}}">{{order.regimenId ? "REGIMEN" : "PRODUCT" }} {{order.regimenId}}</span><span class="pr-2">(</span><span class="pr-2">{{order.stage}}</span><span class="pr-2">)</span>
                <div class="dib mr-2 mt-2 product-label night" *ngFor="let product of order.productInfo;">{{product.name}} &nbsp; {{product.type === apiClientConstant.Catalog.Type.SAMPLE ? '(SAMPLE)' : '' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12"></div>
        <div class="col-xs-6 pl-0 pr-0 pb-1" *ngIf="(order.get('type') == apiClientConstant.Order.Type.REGIMEN && enableRegimenSop) || sopAnswers.length">
          <div class="col-xs-12 list-header">Regimen SOP<a class="fas fa-info-circle ml-3" href="https://docs.google.com/document/d/1M0BNp238gbULVWZRiXT5ykGZo_djEd7Pc2WvZg7TrgQ/edit" target="_blank"></a><i class="fa fa-eye-slash ml-3" *ngIf="showSopSection" (click)="showSopSection = !showSopSection"></i><i class="fa fa-eye ml-3" *ngIf="!showSopSection" (click)="showSopSection = !showSopSection"></i></div>
          <ng-container *ngIf="showSopSection">
            <div class="col-xs-12 p-2 flex" *ngIf="sopAnswers.length" style="flex-wrap: wrap;">
              <div class="b-a rounded ml-1 mt-1" *ngFor="let sopAnswer of sopAnswers;let i = index"><span class="bg-black-opacity pl-1 pr-1 text-white rounded">{{sopAnswer.question}}</span><span class="pl-1 pr-1 rounder">{{sopAnswer.answer}}</span>
                <div class="fas fa-close pl-1 pr-1" (click)="editRegimenSop(i)"></div>
              </div>
            </div>
            <div class="col-xs-12 p-3 pt-0" *ngIf="currentSOPStates?.length">
              <div *ngFor="let node of currentSOPStates;let i = index;">
                <div *ngIf="i < currentSOPStates.length - 1">{{node.message}}</div>
                <div class="col-xs-12 p-0" *ngIf="i === currentSOPStates.length - 1">
                  <div class="col-xs-12">{{node.message}}</div>
                  <div class="col-xs-12 flex alignFS flex-wrap">
                    <div *ngFor="let input of node.inputs">
                      <button class="small btn-secondary mt-3 ml-3" (click)="onSelectButton(input.value)">{{input.display}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-xs-6 p-0">
          <div class="col-xs-12 list-header flex"><span>Send Voice/Text Message</span><i class="fa fa-eye-slash ml-3" *ngIf="showVoiceSection" (click)="showVoiceSection = !showVoiceSection"></i><i class="fa fa-eye ml-3" *ngIf="!showVoiceSection" (click)="showVoiceSection = !showVoiceSection"></i>
            <label class="i-switch i-switch-sm bg-success ml-3 mt-0">
              <input type="checkbox" [(ngModel)]="isAudio" (onChange)="isAudio = !isAudio" name="isAudio"/><i></i>
            </label>
            <div class="pull-right flex ml-3">
              <div class="fontS">{{userCommunicationLanguage}}</div>
            </div>
          </div>
          <ng-container *ngIf="showVoiceSection">
            <form class="col-xs-12 p-3" *ngIf="!isAudio">
              <canned-response-selection [object]="messageData" name="message" [user]="user" [required]="true"></canned-response-selection>
              <textarea [(ngModel)]="messageData.message"></textarea>
              <button class="mt-2" (click)="sendTextMessage()">Send</button>
            </form>
            <div class="col-xs-8 p-0 ml-3" *ngIf="isAudio"></div>
            <label class="px-3 fontS bold flex" *ngIf="showCustomRecording">send Custom Recordings
              <label class="i-switch i-switch-sm bg-success ml-3">
                <input type="checkbox" [(ngModel)]="enableCustomRecordings" (onChange)="enableCustomRecordings = !enableCustomRecordings" name="enableCustomRecordings"/><i></i>
              </label>
            </label>
            <div class="col-xs-12 px-3" *ngIf="enableCustomRecordings">
              <div class="col-xs-12 flex">
                <label class="col-xs-4">Select Concern</label>
                <select class="col-xs-6" [(ngModel)]="selectedConcern" (change)="updateVoiceRecording(selectedConcern)" name="selectedConcern">
                  <option *ngFor="let option of concerns" [value]="option.value">{{option.display}}</option>
                </select>
              </div>
              <div class="col-xs-12 mt-3" *ngIf="selectedConcern">
                <button *ngIf="selectedConcernVoiceRecordingPrefered"><a [href]="selectedConcernVoiceRecordingPrefered" target="_blank">Play In {{userCommunicationLanguage}}</a></button>
                <button class="ml-3" *ngIf="selectedConcernVoiceRecordingEn"><a [href]="selectedConcernVoiceRecordingEn" target="_blank">Play In En</a></button>
              </div>
              <div class="col-xs-3">
                <button class="mt-3" (click)="saveOrderChatAndSendNotification()" [disabled]="!selectedConcern">Send</button>
              </div>
            </div>
            <div class="col-xs-12" *ngIf="!enableCustomRecordings">
              <div class="col-xs-12 px-3">
                <label>Guidelines for voice Recording</label>
                <ol>
                  <li>Intro - Hi (Pts name), I am dr...</li>
                  <li>Observation/ Dx (photos/concerns)</li>
                  <li>Answer patient queries or Treatment considerations/ notes - (Allergies/preg/bf)</li>
                  <li>Treatment plan and expectations</li>
                  <li>When to expect results</li>
                  <li>Please follow the instructions regularly</li>
                  <li>Will connect back with you during monthly follow-ups</li>
                </ol>
              </div>
              <div class="col-xs-12 px-3">
                <audio-recorder (afterSave)="afterSaveRecording($event)" [disableVoiceToText]="true" (onChange)="onAudioRecordingStatusChange($event)"></audio-recorder>
              </div>
              <div class="col-xs-8 p-3">
                <textarea class="w100" [(ngModel)]="audioNote" placeholder="Audio Note for Internal Team (Not Visible To User)"></textarea>
              </div>
              <div class="col-xs-3">
                <button class="ml-3 mt-3" (click)="saveRecording()">Send</button>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-xs-12 p-0">
          <div class="col-xs-12 list-header">Notes<i class="fa fa-eye-slash ml-3" *ngIf="showNotesSection" (click)="showNotesSection = !showNotesSection"></i><i class="fa fa-eye ml-3" *ngIf="!showNotesSection" (click)="showNotesSection = !showNotesSection"></i></div>
          <ng-container *ngIf="showNotesSection">
            <div class="col-xs-12 col-sm-6 px-3 pb-3">
              <div class="col-xs-12 py-3 text-center bold">Operator</div>
              <user-notes-operator [user]="user"></user-notes-operator>
            </div>
            <div class="col-xs-12 col-sm-6 px-3 pb-3">
              <div class="col-xs-12 py-3 text-center bold">Doctor</div>
              <user-notes-doctor [user]="user" [enableAddNote]="true"></user-notes-doctor>
            </div>
          </ng-container>
        </div>
        <div class="col-xs-12 p-0" *ngIf="products">
          <div class="col-xs-12 list-header">Products<a class="fas fa-edit ml-3" routerLink="/order/{{order.id}}/edit" target="_blank"></a></div>
          <div class="col-xs-12 p-3 pr-0">
            <ng-container *ngFor="let product of products;"><a class="inline label label-default m-l-xs m-t-xs pointer wrapper-xs" routerLink="/products/catalog/{{product.id}}" target="_blank">{{product.name}} &nbsp;</a></ng-container>
          </div>
        </div>
        <div class="col-xs-12 p-0" *ngIf="products">
          <div class="col-xs-12 list-header pl-4">Orders<a class="fa fa-plus-square-o pl-3" routerLink="/orders/new" [queryParams]="{ username: user.get('username') }" target="_blank"></a></div>
          <div class="col-xs-12 p-4 orderList">
            <order-list [user]="user" [hideTitle]="true"></order-list>
          </div>
        </div>
        <div class="col-xs-12 p-0" *ngIf="activeRegimens?.length">
          <div class="col-xs-12 list-header">Active Regimens</div>
          <div class="col-xs-12 p-3 pr-0" *ngFor="let activeRegimen of activeRegimens;">
            <div class="col-xs-12 scroll flex">
              <div class="dib flex"><a class="label label-default product-label" routerLink="/products/regimen/{{activeRegimen.objectId}}" target="_blank">{{activeRegimen.regimenId}} ({{activeRegimen.class}})</a><a class="fas fa-edit mr-3" routerLink="/products/regimen/{{activeRegimen.objectId}}/edit" target="_blank"></a></div>
              <div class="dib mt-3"><span class="pl-3">:</span></div>
              <div class="dib mt-3">
                <morning-night-list [regimen]="activeRegimen"></morning-night-list>
              </div>
            </div>
            <div class="col-xs-6 flex fontS2 mt-3">
              <label class="col-xs-4 m-0">Updated At</label><span>{{activeRegimen.updatedAt | amDateFormat: 'MMM DD YYYY,hh:mm a'}}</span>
            </div>
            <div class="col-xs-6 flex fontS2 mt-3">
              <label class="col-xs-4 m-0">Created At</label><span>{{activeRegimen.createdAt | amDateFormat: 'MMM DD YYYY,hh:mm a'}}</span>
            </div>
          </div>
        </div>
        <div class="col-xs-12 p0" *ngIf="regimen">
          <div class="col-xs-12 list-header">Regimen<span *ngIf="regimen.get('selectedVariantId')">({{regimen.get('selectedVariantId')}})</span></div>
          <div class="col-xs-12 p0" *ngIf="regimen && !enableRegimenSop">
            <regimen-edit class="col-xs-12 p0" [regimen]="regimen" (afterSaveRegimen)="afterSaveRegimen($event)" [editType]="regimenUpdatedThroughSop ? apiClientConstant.RegimenSop.RegimenSop : ''" [hideNotNecessaryThings]="true"></regimen-edit>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="w-full" *ngIf="enableDrObservationExperiment == true">
            <div class="col-xs-12 flex-column p-0">
              <div class="col-xs-12">
                <div class="list-header capitalize">Doctor, kindly confirm which of the following have been informed to the patient during this approval your voice note:-
                  <div class="col-xs-12 flex">
                    <ol>
                      <li class="col-xs-12 flex p-2">
                        <label class="col-xs-3">Address Concern Change</label>
                        <input class="col" [(ngModel)]="drObservation.addressConcern" type="checkbox"/>
                      </li>
                      <li class="col-xs-12 flex p-2">
                        <label class="col-xs-3">Patient query addressed</label>
                        <input class="col-xs-8" [(ngModel)]="drObservation.patientQueryAddressed" type="text"/>
                      </li>
                      <li class="col-xs-12 flex p-2">
                        <label class="col-xs-3">Addressed special condition</label>
                        <input class="col-xs-8" [(ngModel)]="drObservation.specialConditionAddress" type="text"/>
                      </li>
                      <li class="col-xs-12 flex p-2">
                        <label class="col-xs-3">What to expect</label>
                        <input class="col-xs-8" [(ngModel)]="drObservation.whatExcept" type="text"/>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 p-0 mb-2">
          <div class="col-xs-12 list-header">Order Notes</div>
          <order-note [order]="order"></order-note>
        </div>
        <section class="section w100">
          <consent-form #consentForm [user]="user" [currentUser]="currentUser" [isIsotretConsent]="isIsotretConsent" [isFinasterideConsent]="isFinasterideConsent" [order]="order" (sendFormEmitter)="wantsToTriggerConsentForm($event)" (cancelFormEmitter)="wantsToCancelSendingConsentForm()"></consent-form>
        </section>
      </div>
    </div>
  </div>
  <ng-template #conifrmOrderCancelDialog>
    <div class="dialog-container">
      <h2 mat-dialog-title="mat-dialog-title">Are you sure you want to cancel the order?</h2>
      <mat-dialog-actions align="end">
        <button class="mr-3 small btn-secondary bold" [mat-dialog-close]="false"> Close</button>
        <button class="small btn-danger bold" [mat-dialog-close]="true"> Cancel Order</button>
      </mat-dialog-actions>
    </div>
  </ng-template>
</div>
<div class="col-xs-12 newUIHeader p-0" #newHeader *ngIf="showNewUI">
  <div class="hbox" #orderView fillComponentParentView="fillComponentParentView">
    <div class="flex-box" *ngIf="user">
      <div class="flex-heading">
        <div class="col-xs-12 list-header listHeader flex">
          <div class="pull-left user-details text-capitalize fontL4 fw-700"><a target="_blank" routerLink="/chat/{{user.id}}"><span class="text-capitalize">{{user.get('PatientName')}}</span><span class="text-capitalize" *ngIf="user.get('Age')">&nbsp;| {{user.get('Age')}}</span><span class="text-capitalize" *ngIf="user.get('Gender')">&nbsp;| {{user.get('Gender')}}</span><span class="text-capitalize" *ngIf="user.get('languagePreference')">&nbsp;| {{userCommunicationLanguage}}</span><span class="text-capitalize" *ngIf="user.get('lastLocationCity')">&nbsp;| {{user.get('lastLocationCity')}}</span><span class="text-capitalize" *ngIf="user.get('lastLocationState')">&nbsp;| {{user.get('lastLocationState')}}</span><span class="text-capitalize fw-700" *ngIf="user.get('PrivateMainConcern')">&nbsp; | &nbsp;{{user.get('PrivateMainConcern')?.split('_').join(' ').toLowerCase()}}</span><span class="text-capitalize fw-700" *ngIf="sensitiveSkin">| {{sensitiveSkin}}</span><span class="text-capitalize fw-700" *ngIf="this.order.get('type') === appConfig.Shared.Order.Type.REGIMEN">&nbsp; | {{order.get('regimen')?.get('class').toLowerCase()}}</span></a><span class="highlighed-text ml-5 fw-700" *ngIf="user.get('marketingTags')?.includes('STEROID')">&nbsp; STEROID</span><span class="highlighed-text ml-5 fw-700" *ngIf="regimen?.get('numberOfProductsLimit') && regimen?.get('numberOfProductsLimit') > 4">{{ regimen?.get('numberOfProductsLimit') }} products</span><span class="highlighed-text ml-5 fw-700" *ngIf="pregnentMessages.includes(extraData.pregnancy)">Pregnant</span><span class="highlighed-text ml-5 fw-700" *ngIf="breastfeedingMessages.includes(extraData.pregnancy)">Breastfeeding</span><span class="text-capitalize clr-red" *ngIf="shouldCallUserBeforeApproval">
              <div class="alert-danger bold fw-700">User opted for call before order approval</div></span><span class="flex" *ngIf="!order.get('doctorConfirmed')">
              <button class="mr-3 small bold" (click)="captureOrder()">Capture</button></span></div>
          <div class="fontL2 text-cap flex" *ngIf="order.get('doctorConfirmed')">
            <div class="flex" *ngIf="shouldCallUserBeforeApproval">
              <div class="alert-danger bold">User opted for call before order approval</div>
            </div><span class="highlighed-text ml-5 fw-700" *ngIf="orderStage === appConfig.Shared.Order.Stage.DOCTOR_APPROVAL_PENDING && order.get('regimen')?.get('optedForDoctorCall') && !callAlreadyDone">Call</span><span class="highlighed-text ml-5 fw-700" *ngIf="orderStage === appConfig.Shared.Order.Stage.DOCTOR_APPROVAL_PENDING && order.get('regimen')?.get('optedForDoctorCall') === false">Voice note</span>
            <button class="mr-3 cancelBtn small btn-secondary" (click)="cancelOrderNew()">Cancel Order</button>
            <button class="done-finish-button btn-xs ml-3" *ngIf="orderStage === appConfig.Shared.Order.Stage.DOCTOR_APPROVAL_PENDING && !isProductOrder" [ngClass]="{'disabled-button': isBtnDisabled, 'disabled-button-no': !isBtnDisabled}" (click)="saveRegimenAndApproveNew()">Approve Regimen</button>
            <button class="done-finish-button disabled-button-no btn-xs ml-3" *ngIf="isProductOrder && orderStage === appConfig.Shared.Order.Stage.DOCTOR_APPROVAL_PENDING " (click)="saveRegimenAndApproveNew()">Approve Products</button>
            <div class="posR pointer" (click)="callUserThroughExotel(user.get('MobileNumber'))"><em class="fa-solid fa-phone ml-3 call-icon" *ngIf="user.get('MobileNumber')" title="Mobile"></em><span class="call-icon-plus">+</span></div>
          </div>
        </div>
      </div>
      <div class="flex-content" #content>
        <section class="flex gap-12 w100 mt-3">
          <div class="flex alignFS gap-12 w100">
            <div class="flex flex-dir-col gap-12 w50 chat-section">
              <div class="dib section w100 chat-summary">
                <div class="heading bg-white">
                  <quick-action-buttons (sectionToScrollTo)="onChooseSection($event)" [quickActions]="quickActions"></quick-action-buttons>
                  <div class="flex right-align" id="chatSection"><span>chat view</span>
                    <input type="checkbox" id="toggle" [(ngModel)]="selectChat" (ngModelChange)="changeSelectedChatType(selectChat)" name="selectedChatType"/>
                    <label class="labelToggle" for="toggle"></label>
                  </div>
                </div>
                <user-chat-view-human-interaction *ngIf="selectedChatType === 'userChat'" [privateMainConcernClass]="regimen.get('class')" [user]="user" [height]="'100%'"></user-chat-view-human-interaction>
                <user-chat-view-summary [user]="user" *ngIf="selectedChatType === 'chatSummary'" [regimenClass]="regimen?.get('class')" [fromNewOrderApprovalPage]="true" [isProductOrder]="isProductOrder"></user-chat-view-summary>
              </div>
              <section class="section w100 dib h-fit-content">
                <div *ngIf="(order.get('type') == appConfig.Shared.Order.Type.REGIMEN && enableRegimenSop) || sopAnswers.length">
                  <div class="voice-recorder" style="padding: 3px">
                    <ng-container *ngIf="showSopSection">
                      <div class="col-xs-12 p-1 flex" *ngIf="sopAnswers.length" style="flex-wrap: wrap;flex-direction: row; align-items: flex-start;">
                        <div class="ml-1 mt-1 divBlock" *ngFor="let sopAnswer of sopAnswers;let i = index"><span class="pl-1 pr-1 msgHeader">{{sopAnswer.question}}</span><span class="pl-1 pr-1 ansGreen ml-1">{{sopAnswer.answer}}</span>
                          <div class="fas fa-close pl-1 pr-1 mr-2" (click)="editRegimenSop(i)"></div>
                        </div>
                      </div>
                      <div class="col-xs-12 p-3 pt-0" *ngIf="currentSOPStates?.length">
                        <div *ngFor="let node of currentSOPStates;let i = index;">
                          <div *ngIf="i < currentSOPStates.length - 1">{{node.message}}</div>
                          <div class="flex justify-space" *ngIf="i === currentSOPStates.length - 1"><span class="msgHeader">{{node.message}}</span><span class="flex alignFS flex-wrap ml-5">
                              <div class="mr-1" *ngFor="let input of node.inputs">
                                <button class="small btn-secondary mt-3 btn-cus" (click)="onSelectButton(input.value, node.message)">{{input.display}}</button>
                              </div></span></div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </section>
            </div>
            <section class="section flex-grow">
              <instant-checkup-magnifier [user]="user" (needHelpButtonClicked)="markOrderWaitingForImageNew()"></instant-checkup-magnifier>
            </section>
          </div>
        </section>
        <div class="section w100" id="contentOne">
          <div class="col-xs-12 py-3 text-center" *ngIf="waitingForImageConfirmation">
            <form #waitingForImageForm="ngForm">
              <label class="fw-700 bold floatL ml-4">Why is the order moved to "Waiting For Image" ?</label>
              <div class="col-xs-9 ml-4 flex">
                <div class="col-xs-12 p-0 m-0">
                  <mat-select [(ngModel)]="selectedWaitingForImageReason" name="selectedWaitingForImageReasons" placeholder="Select Reason(s)" [formControl]="formController" required="true" style="min-width:150px;padding:4px;outline:1px solid black;">
                    <mat-option *ngFor="let item of appConfig.Shared.Order.WaitingForImageReasons" [value]="item.key">{{item.key}}</mat-option>
                  </mat-select>
                </div>
                <div class="col-xs-3">
                  <button class="btn-xs btn-primary-outline ml-3" [disabled]="!waitingForImageForm.valid || !selectedWaitingForImageReason" (click)="confirmWaitingForImageNew()">Confirm</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <section class="section w100">
          <doctor-notes [username]="user.get('username')"></doctor-notes>
        </section>
        <section class="section w100" id="productSection" *ngIf="isProductOrder">
          <div class="hbox">
            <div class="voice-recorder mb-unset">
              <div class="col-xs-12 title mb-unset">Products<a class="fas fa-edit ml-3" routerLink="/order/{{order.id}}/edit" target="_blank"></a></div>
              <div class="col-xs-12 p-3 pr-0">
                <ng-container *ngFor="let product of products;"><a class="inline fontL label label-default m-l-xs m-t-xs pointer wrapper-xs" routerLink="/products/catalog/{{product.id}}" target="_blank">{{product.name}} &nbsp;</a></ng-container>
              </div>
            </div>
          </div>
        </section>
        <section class="section w100" *ngIf="isProductOrder">
          <div class="hbox">
            <div class="voice-recorder mb-unset">
              <div class="col-xs-12 title mb-unset">Orders<a class="fa fa-plus-square-o pl-3" routerLink="/orders/new" [queryParams]="{ username: user.get('username') }" target="_blank"></a></div>
              <div class="col-xs-12 p-4 orderList pt-unset">
                <order-list [user]="user" [hideTitle]="true"></order-list>
              </div>
            </div>
          </div>
        </section>
        <section class="w100" id="regimenSection">
          <section class="w100" id="regimenSelection" *ngIf="enableRegimenSop && regimenList?.length && !isProductOrder">
            <div class="section w100" *ngIf="regimenList?.length">
              <regimen-products-edit #RegimenProductsEditComponent (isRegimenSelected)="onSelectRegimenFromSop($event)" (afterSaveRegimen)="afterSaveRegimen($event)" [regimen]="order.get('regimen')" [regimenList]="regimenList" [disableEdit]="disableRegimenEdit" [orderApprovalPage]="true" [isEditAllowed]="order.get('stage') === appConfig.Shared.Order.Stage.DOCTOR_APPROVAL_PENDING"></regimen-products-edit>
            </div>
          </section>
          <section class="section w100" *ngIf="!enableRegimenSop && !isProductOrder">
            <regimen-products-edit #RegimenProductsEditComponent [regimen]="order.get('regimen')" (afterSaveRegimen)="afterSaveRegimen($event)" [disableEdit]="disableRegimenEdit" [orderApprovalPage]="true"></regimen-products-edit>
          </section>
          <section class="section w100" *ngIf="isProductOrder && activeRegimens?.length">
            <div class="hbox">
              <div class="voice-recorder mb-unset">
                <div class="col-xs-12 title mb-unset">Active Regimens</div>
                <div class="col-xs-12 p-3 pr-0" *ngFor="let activeRegimen of activeRegimens;">
                  <div class="col-xs-12 scroll flex">
                    <div class="dib flex"><a class="label label-default product-label" routerLink="/products/regimen/{{activeRegimen.objectId}}" target="_blank">{{activeRegimen.regimenId}} ({{activeRegimen.class}})</a><a class="fas fa-edit mr-3" routerLink="/products/regimen/{{activeRegimen.objectId}}/edit" target="_blank"></a></div>
                    <div class="dib mt-3" style="margin-right: 10px; margin-bottom: 10px;"><span class="pl-3">:</span></div>
                    <div class="dib mt-3">
                      <morning-night-list [regimen]="activeRegimen"></morning-night-list>
                    </div>
                  </div>
                  <div class="col-xs-6 flex fontS2 mt-3">
                    <label class="col-xs-4 m-0">Updated At</label><span>{{activeRegimen.updatedAt | amDateFormat: 'MMM DD YYYY,hh:mm a'}}</span>
                  </div>
                  <div class="col-xs-6 flex fontS2 mt-3">
                    <label class="col-xs-4 m-0">Created At</label><span>{{activeRegimen.createdAt | amDateFormat: 'MMM DD YYYY,hh:mm a'}}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section class="section w100">
          <consent-form #consentForm [user]="user" [currentUser]="currentUser" [isIsotretConsent]="isIsotretConsent" [isFinasterideConsent]="isFinasterideConsent" [order]="order" (sendFormEmitter)="wantsToTriggerConsentForm($event)" (cancelFormEmitter)="wantsToCancelSendingConsentForm()"></consent-form>
        </section>
        <section class="section w100" id="voiceRecordingSection">
          <div class="flex col-xs-12" style="border-bottom: 1px solid rgba(0,0,0,0.1)">
            <label class="text-capitalize fw-700" style="font-size: 22px;">Voice Note &nbsp;</label>
            <div class="voiceNoteUi" *ngIf="showCustomRecording"><span>auto</span>
              <input class="ml-3" type="checkbox" id="toggleVoice" [(ngModel)]="enableCustomRecordings" (onChange)="enableCustomRecordings = !enableCustomRecordings" name="enableCustomRecordings"/>
              <label class="labelToggle" for="toggleVoice"></label>
            </div>
          </div>
          <voice-recording #VoiceRecordingComponent (afterSave)="afterSaveRecording($event)" (onChange)="onAudioRecordingStatusChange($event)" [disableVoiceToText]="true" *ngIf="!enableCustomRecordings" [orderApprovalPage]="true"></voice-recording>
          <div class="col-xs-12 px-3 pb-5" *ngIf="enableCustomRecordings">
            <div class="col-xs-12 flex pt-3" style="align-items: end">
              <label style="margin-right: 1.5rem;">Selected Concern</label>
              <input-select-text [options]="concerns" (onValueChanged)="updateVoiceRecording($event)" selectWidth="200px"></input-select-text>
            </div>
            <div class="col-xs-12 mt-3" *ngIf="selectedConcern" #audioPlayer></div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <ng-template #conifrmOrderCancelDialog>
    <div class="dialog-container">
      <h2 mat-dialog-title="mat-dialog-title">Are you sure you want to cancel the order?</h2>
      <mat-dialog-actions align="end">
        <button class="mr-3 small btn-secondary bold" [mat-dialog-close]="false"> Close</button>
        <button class="small btn-danger bold" [mat-dialog-close]="true"> Cancel Order</button>
      </mat-dialog-actions>
    </div>
  </ng-template>
</div>
