import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import { DirectivesModule } from '../../../../components/directives.module';
import { CatalogViewComponent } from './catalog-view.component';
import { CatalogVariantModal } from './catolog-variant-modal';
import { InputTextModule } from '../../../../components/input/input-text';
import { InputImageUploadModule } from '../../../../components/input/input-image-upload';
import { InputCheckboxModule } from '../../../../components/input/input-checkbox';
import { InputSelectModule } from '../../../../components/input/input-select';
import { CatalogInstructionComponent } from './catalog-instruction/catalog-instruction.component';
import { CatalogInstructionEditModal } from './catalog-instruction/catolog-instruction-edit-modal';
import { LanguageStringSelectionModule } from '../../../../components/language-string/language-string-selection';
import { LanguageStringTagSelectionModule } from '../../../../components/language-string/language-string-tag-selection';
import { CatalogInstructionViewModule } from './catalog-instruction/catolog-instruction-view-modal';
import {
  LanguageStringMultipleSelectionWithTagModule,
// eslint-disable-next-line max-len
} from '../../../../components/language-string/language-string-multiple-selection-with-tag/language-string-multiple-selection-with-tag.module';
import { LanguageViewModule } from '../../../../components/language-string/language-view';
import { CatalogSelectionModule } from '../../../../components/catalog-selection/catalog-selection.module';
import { CatalogSelectInstructionToCopyModule } from './catalog-instruction/catolog-select-instruction-to-copy-modal';
import { InstructionVideoUploadModule } from '../../../../components/instruction-video-upload';

const ROUTES: Routes = [
  { path: '', component: CatalogViewComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    DirectivesModule,
    DragDropModule,
    InputTextModule,
    InputCheckboxModule,
    InputSelectModule,
    LanguageStringSelectionModule,
    LanguageStringTagSelectionModule,
    CatalogInstructionViewModule,
    LanguageStringMultipleSelectionWithTagModule,
    InputImageUploadModule,
    LanguageViewModule,
    CatalogSelectionModule,
    CatalogSelectInstructionToCopyModule,
    InstructionVideoUploadModule,
    MatExpansionModule,
    RouterModule.forChild(ROUTES),
  ],
  declarations: [
    CatalogViewComponent,
    CatalogVariantModal,
    CatalogInstructionComponent,
    CatalogInstructionEditModal,
  ],
  exports: [
    CatalogViewComponent,
    CatalogVariantModal,
    CatalogInstructionComponent,
    CatalogInstructionEditModal,
  ],
})
export class CatalogViewModule {
}
