import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DirectivesModule } from 'src/components/directives.module';
import { MatDialogModule } from '@angular/material/dialog';
import { SalesComponent } from './sales.component';
import { HeaderComponent } from './components/header/header.component';
import { UserPhotosComponent } from './components/user-photos/user-photos.component';
import { PatientInfoComponent } from './components/patient-info/patient-info.component';
import { ChatSidebarModule } from '../chat/view/chat-sidebar/chat-sidebar.module';
import { SalesDoctorNotesComponent } from './components/doctor-notes/doctor-notes.component';
import { SalesSupportTicketComponent } from './components/sales-support-ticket/sales-support-ticket.component';
import { SalesCallLogsComponent } from './components/sales-call-logs/sales-call-logs.component';
import { SalesOrderHistoryComponent } from './components/sales-order-history/sales-order-history.component';
import { SalesRegimenVariantsComponent } from './components/sales-regimen-variants/sales-regimen-variants.component';
import { SalesOrderInfoComponent } from './components/sales-order-info/sales-order-info.component';
import { SalesOrderNotesComponent } from './components/sales-order-notes/sales-order-notes.component';
import { SalesPaymentsComponent } from './components/sales-payments/sales-payments.component';

const appRoutes: Routes = [
  { path: 'newSales', component: SalesComponent },
];

@NgModule({
  declarations: [SalesComponent],
  imports: [
    CommonModule,
    DirectivesModule,
    RouterModule.forChild(appRoutes),
    ChatSidebarModule,
    PatientInfoComponent,
    UserPhotosComponent,
    SalesDoctorNotesComponent,
    SalesSupportTicketComponent,
    SalesCallLogsComponent,
    SalesOrderHistoryComponent,
    SalesRegimenVariantsComponent,
    SalesOrderInfoComponent,
    SalesPaymentsComponent,
    SalesOrderNotesComponent,
    MatDialogModule,
    HeaderComponent,
  ],
  exports: [SalesComponent],
})
export class SalesModule { }
